import React from 'react'
import styles from './GenericSessionScheduleTable.module.css'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material'
import Collapse from '@mui/material/Collapse'

interface Props {
  id: string
  imageUrl: string
  title: string
  head: any
  rows: any[]
}

export const GenericSessionScheduleTable: React.FC<Props> = ({
  id,
  imageUrl,
  title,
  head,
  rows,
}) => {
  const [showDetails, setShowDetails] = React.useState(false)
  const isMobile = useMediaQuery('(max-width: 767px)')

  // useEffect(() => {
  //   if (isMobile) {
  //     setShowDetails(false)
  //   }
  // }, [isMobile])

  // useEffect(() => {
  //   setShowDetails(false)
  // }, [])

  return (
    <div id={id} className={styles.container}>
      <div
        style={{
          marginTop: '24px',
          fontSize: '25px',
          fontWeight: 'bold',
          color: 'rgba(0,0,0,1)',
        }}
      >
        Course schedule
      </div>
      <div
        style={{
          marginTop: '36px',
          width: '100%',
          paddingLeft: isMobile ? '0px' : '60px',
          paddingRight: isMobile ? '0px' : '60px',
        }}
      >
        <div
          style={{
            backgroundColor: 'rgba(0,0,0,1)',
            color: 'rgba(255,255,255,1)',
            width: '100%',
            paddingTop: '20px',
            paddingLeft: isMobile ? '8px' : '48px',
            paddingRight: isMobile ? '8px' : '48px',
            borderRadius: '20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '24px',
            }}
          >
            <div style={{ flex: 1 }}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'start',
                }}
              >
                <img src={imageUrl} alt={imageUrl} style={{ width: '250px' }} />
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <span
                style={{
                  fontSize: '25px',
                  fontWeight: 700,
                  color: 'rgba(255,255,255,1)',
                }}
              >
                {title}
              </span>
            </div>
          </div>
          <div>
            <Collapse in={showDetails}>
              <TableContainer className={styles.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {Object.keys(head).map((key, index) => (
                        <TableCell
                          key={index}
                          className={index % 2 === 0 ? styles.dark : ''}
                          align="center"
                          style={
                            index === Object.keys(head).length - 1
                              ? { width: '110px' }
                              : {}
                          }
                        >
                          <span
                            style={{
                              fontFamily: 'Inter',
                              fontSize: '16px',
                              fontWeight: 700,
                            }}
                          >
                            {head[key]
                              .split('\\n')
                              .map((item: string, index: any) => (
                                <span key={index}>
                                  {item}
                                  <br />
                                </span>
                              ))}
                          </span>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {Object.keys(row).map((key, index) => (
                          <TableCell
                            key={index}
                            className={index % 2 === 1 ? styles.dark : ''}
                            align="center"
                            style={{
                              fontFamily: 'Roboto',
                              fontSize: '14px',
                              fontWeight: 600,
                              color:
                                rowIndex === rows.length - 1 &&
                                index === Object.keys(row).length - 1
                                  ? 'rgba(186, 97, 255,1)'
                                  : 'rgba(0,0,0,0.87)',
                            }}
                          >
                            {row[key]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </div>
          <div
            onClick={() => setShowDetails(!showDetails)}
            style={{
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontWeight: 'bold',
              padding: '24px',
              cursor: 'pointer',
            }}
          >
            {showDetails ? 'Hide details —' : 'Show details ＋'}
          </div>
        </div>
      </div>
    </div>
  )
}
