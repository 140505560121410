import React from 'react'
import styles from './GenericCourseSummary.module.css'

interface Props {
  data: {
    title: string
    titleColor: string
    itemTitleColor: string
    itemDescriptionColor: string
    items: {
      title: string
      description: string
    }[]
  }
}

export default function GenericCourseSummary({
  data: { title, titleColor, itemTitleColor, itemDescriptionColor, items },
}: Props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '12px',
        paddingLeft: '10%',
        paddingRight: '10%',
      }}
    >
      <p
        style={{
          color: titleColor,
          fontFamily: 'Inter',
          fontSize: '60px',
          fontWeight: '700',
          textAlign: 'center',
        }}
        className={styles.title}
      >
        {title}
      </p>
      <div className={styles.content}>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '12px',
                gap: '6px',
                width: '100%',
                // backgroundColor: 'red',
              }}
            >
              <p
                style={{
                  color: itemTitleColor,
                  fontFamily: 'Inter',
                  fontSize: '25px',
                  fontWeight: '700',
                  textAlign: 'center',
                }}
                className={styles.itemTitle}
              >
                {item.title}
              </p>
              <div
                style={{
                  color: itemDescriptionColor,
                  fontFamily: 'Inter',
                  fontSize: '20px',
                  fontWeight: '600',
                  padding: '12px',
                  lineHeight: 'normal',
                }}
                className={styles.itemDescription}
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </div>
            {index !== items.length - 1 && <div className={styles.divider} />}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
