import React from 'react'
import styles from './SessionFaqForRla.module.css'
import { styled } from '@mui/material/styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import PlusIcon from '@mui/icons-material/Add'
import MinusIcon from '@mui/icons-material/Remove'
import { Box, useMediaQuery } from '@mui/material'

const CustomExpandIcon = ({ index }: { index: number }) => {
  return (
    <Box
      sx={{
        '.Mui-expanded & > .collapsIconWrapper': {
          display: 'none',
        },
        '.expandIconWrapper': {
          display: 'none',
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block',
          color: index % 2 === 0 ? 'white' : 'black',
        },
        color: 'black',
      }}
    >
      <div className="expandIconWrapper">
        <MinusIcon />
      </div>
      <div className="collapsIconWrapper">
        <PlusIcon />
      </div>
    </Box>
  )
}

const Accordion = styled((props: AccordionProps & { index: number }) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, index }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
  '& .Mui-expanded.MuiAccordionSummary-root': {
    backgroundColor: index % 2 === 0 ? '#0A1F48' : '#C9CDE5',
    color: index % 2 === 0 ? 'white' : 'black',
  },
}))

const AccordionSummary = styled(
  (props: AccordionSummaryProps & { index: number }) => (
    <MuiAccordionSummary
      expandIcon={<CustomExpandIcon index={props.index} />}
      {...props}
    />
  ),
)(({ theme }) => ({
  fontWeight: 700,
  // backgroundColor: 'rgba(255, 255, 255, .05)',
  // flexDirection: 'row-reverse',
  // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
  //   transform: 'rotate(90deg)',
  // },
  // '& .MuiAccordionSummary-content': {
  //   marginLeft: theme.spacing(1),
  // },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  color: '#3B317D',
  fontWeight: 500,
  fontSize: '16px',
  fontFamily: 'Roboto',
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

interface Props {
  items: { question: string; answer: string }[]
  cta?: {
    title: string
    description: string
    email: string
  }
}

export const SessionFaqForRla: React.FC<Props> = ({ items, cta }) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <div id="faqs" className={styles.container}>
      <div
        className={styles.content}
        style={{
          maxWidth: '800px',
        }}
      >
        <div className={styles.title}>FAQs</div>
        {items.map((qa, index) => (
          <Accordion key={index} index={index}>
            <AccordionSummary
              index={index}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              {index + 1}. {qa.question}
            </AccordionSummary>
            <AccordionDetails>
              <div
                className={styles.answer}
                dangerouslySetInnerHTML={{ __html: qa.answer }}
              />
            </AccordionDetails>
          </Accordion>
        ))}

        <div
          style={{
            marginTop: '60px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <h1 style={{ fontSize: isMobile ? '30px' : '48px' }}>
            {cta?.title || 'Have more questions?'}
          </h1>
          <p className={styles.description}>
            {cta?.description ||
              'If you have any questions about this course please reach out to'}
            <br />
            <a href={`mailto:${cta?.email || 'hello@mentem.co'}`}>
              <span style={{ color: 'rgba(186, 97, 255, 1)' }}>
                {cta?.email || 'hello@mentem.co'}
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
