import React from 'react'
import styles from './SessionCTAForRla.module.css'
import { Link } from 'react-router-dom'

export const SessionCTAForRla: React.FC = () => {
  const title = 'Get started'
  const subtitle = 'Tell us about yourself'
  const buttonText = 'Apply now'

  return (
    <div id="eoi" className={styles.container}>
      <Link to="form">
        <div
          style={{
            background: 'transparent',
            overflow: 'hidden',
            borderRadius: '20px',
          }}
        >
          <div className={styles.frame}>
            <div className={styles.content}>
              <h1 className={styles.title}>{title}</h1>
              <p className={styles.subtitle}>
                <b>{subtitle}</b>
              </p>
              <br></br>
              <div className={styles.description}>
                <p>
                  Express your interest in the program by completing a short
                  form.{' '}
                </p>
                It only takes <b>5 minutes</b> to share your hopes for the
                course and help us gauge your level. <br />
                <b>Click below to get started.</b>
              </div>
              <button className={styles.cta}>{buttonText}</button>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}
