import React, { useEffect, useRef } from 'react'

interface DynamicHeightIframeProps {
  src: string
  className?: string
}

const DynamicHeightIframe: React.FC<DynamicHeightIframeProps> = ({
  src,
  className,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const handleResize = (event: MessageEvent) => {
      if (iframeRef.current) {
        // const height = 1000
        // iframeRef.current.style.height = `${height}px`
        console.log('event.data.height:', event.data.height)
      }
    }

    window.addEventListener('message', handleResize)

    return () => {
      window.removeEventListener('message', handleResize)
    }
  }, [])

  return (
    <iframe
      style={{
        width: '100%',
        maxWidth: '100%',
        height: '100vh',
        border: 'none',
      }}
      ref={iframeRef}
      src={src}
      title="Dynamic Height Iframe"
      // className={className}
    />
  )
}

export default DynamicHeightIframe
