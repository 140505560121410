import React from 'react'
import styles from './GenericSessionSchedule.module.css'

interface SessionScheduleProps {
  title: string
  description: string
}

export const GenericSessionSchedule: React.FC<SessionScheduleProps> = ({
  title,
  description,
}) => {
  return (
    <div id="schedule" className={styles.container}>
      <div className={styles.content}>
        <div className={styles.part1}>
          <span
            className={styles.title}
            style={{
              color: 'rgba(186, 97, 255, 1)',
            }}
          >
            {title}
          </span>
          <img src="/images/logo-all.png" alt="All logos" width={'100%'} />
        </div>
        {/* divider */}
        <div className={styles.divider} />
        <div className={styles.part2}>
          <div
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </div>
  )
}
