import React from 'react'
import styles from './GenericProgramAttachment.module.css'
import axios from 'axios'
import { uuid } from 'apis/utils/UUID'

interface Props {
  title: string
  description: string
  imageUrl: string
  files: [
    {
      title: string
      url: string
    },
  ]
}

export const GenericProgramAttachment: React.FC<Props> = ({
  title,
  description,
  imageUrl,
  files,
}) => {
  const background = ''

  const handleDownload = async (fileUrl: string) => {
    try {
      const urlParts = fileUrl.split('/')
      const fileName = urlParts[urlParts.length - 1]

      console.log('Downloaded file:', fileName)

      const response = await axios.get(fileUrl, {
        responseType: 'blob', // Important for binary data
      })

      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]))

      // Create a link element
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName) // Use extracted filename
      document.body.appendChild(link)
      link.click()

      // Clean up and remove the link
      link.remove()
      window.URL.revokeObjectURL(url) // Free up memory
    } catch (error) {
      console.error('Error downloading the file:', error)
    }
  }

  return (
    <div
      className={styles.container}
      style={{ backgroundImage: `url('${background}')` }}
    >
      <div>
        <div
          style={{
            fontSize: '25px',
            fontWeight: 700,
            color: 'rgba(0,0,0,1)',
            marginBottom: '32px',
          }}
        >
          {title}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '220px',
              paddingLeft: '16px',
              paddingRight: '16px',
            }}
          >
            <img src={imageUrl} alt={title} style={{ width: '100%' }}></img>
          </div>
          <div
            style={{
              width: '320px',
              fontWeight: 'bold',
              textAlign: 'left',
            }}
          >
            <div className={styles.description}>{description}</div>
            {files.map((file, index) => (
              <button
                key={uuid()}
                className={styles.downloadcta}
                onClick={() => handleDownload(file.url)}
              >
                {file.title}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
