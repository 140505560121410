import React from 'react'
import styles from './GenericSessionBox.module.css'
import { Link } from 'react-router-dom'

interface Props {
  title: string
  content1: string
  content2: string
  buttonText: string
}

export const GenericSessionBox: React.FC<Props> = ({
  title,
  content1,
  content2,
  buttonText,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div>
          <span className={styles.descriptionBlack}>{content1}</span> <br />
          <span className={styles.descriptionBlack}>{content2}</span>
        </div>
        <Link to="form">
          <button className={styles.button}>{buttonText}</button>
        </Link>
      </div>
    </div>
  )
}
