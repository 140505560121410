import React from 'react'
import styles from './GenericSessionFaq.module.css'
import { styled } from '@mui/material/styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import PlusIcon from '@mui/icons-material/Add'
import MinusIcon from '@mui/icons-material/Remove'
import { Box, useMediaQuery } from '@mui/material'

interface GenericSessionFaqProps {
  items: { question: string; answer: string }[]
  cta?: {
    title: string
    description: string
    email: string
  }
  background?: string
  questionColor?: string
  answerColor?: string
}

export const GenericSessionFaq: React.FC<GenericSessionFaqProps> = ({
  items,
  cta,
  background,
  questionColor,
  answerColor,
}) => {
  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          '.Mui-expanded & > .collapsIconWrapper': {
            display: 'none',
          },
          '.expandIconWrapper': {
            display: 'none',
          },
          '.Mui-expanded & > .expandIconWrapper': {
            display: 'block',
          },
          color: 'black',
        }}
      >
        <div className="expandIconWrapper">
          <MinusIcon />
        </div>
        <div className="collapsIconWrapper">
          <PlusIcon />
        </div>
      </Box>
    )
  }

  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
    '& .Mui-expanded.MuiAccordionSummary-root': {
      backgroundColor: 'rgba(239, 239, 239, 1)',
    },
  }))

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<CustomExpandIcon />} {...props} />
  ))(({ theme }) => ({
    fontWeight: 700,
    color: questionColor,
  }))

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    color: answerColor,
    fontWeight: 500,
    fontSize: '16px',
    fontFamily: 'Roboto',
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }))

  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <>
      <img
        src={background || 'https://content.mentem.co/eoi/_share/bottom.jpg'}
        alt="bottom"
        style={{
          position: 'absolute',
          width: '100%',
          bottom: '220px',
          zIndex: -1,
        }}
      />
      <div id="faqs" className={styles.container}>
        <div
          className={styles.content}
          style={{
            maxWidth: '800px',
          }}
        >
          <div className={styles.title}>FAQs</div>
          {items.map((qa, index) => (
            <Accordion key={index}>
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                {qa.question}
              </AccordionSummary>
              <AccordionDetails>
                <div
                  className={styles.answer}
                  dangerouslySetInnerHTML={{ __html: qa.answer }}
                />
              </AccordionDetails>
            </Accordion>
          ))}

          <div
            style={{
              marginTop: '60px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <h1 style={{ fontSize: isMobile ? '30px' : '48px' }}>
              {cta?.title || 'Have more questions?'}
            </h1>
            <p className={styles.description}>
              {cta?.description ||
                'If you have any questions about this course please reach out to'}
              <br />
              <a href={`mailto:${cta?.email || 'hello@mentem.co'}`}>
                <span style={{ color: 'rgba(186, 97, 255, 1)' }}>
                  {cta?.email || 'hello@mentem.co'}
                </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
