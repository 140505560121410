export const textFieldOutlineLabelStyles = {
  //////////////////////////
  // label
  //////////////////////////
  width: '100%',

  //placeholder in textfield and shrinked label on top
  '& label': {
    marginLeft: '10px',
    transition: `all 0.1s ease`,
    // backgroundColor: 'rgba(232, 226, 234, 1)',
    padding: '4px 12px',
    marginTop: '-4px',
    borderRadius: '14px',
  },
  //main label
  '& .MuiInputBase-input': {
    // boxShadow: `inset 0px 1px 0px 0px rgba(125, 129, 159, 0.9)`,
    // transform: `translate(14px, -9px) scale(0.75)`, default
    transform: `translate(0px, 7px)`,
    marginTop: '16px',
    paddingTop: '0px',
    marginBottom: '16px',
    paddingBottom: '0px',
    boxShadow: `inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255, 1)`,
  },
  //shrinked label on top
  '& .MuiInputLabel-shrink': {
    // boxShadow: `inset 0px 1px 0px 0px rgba(125, 129, 159, 0.9)`,
    // transform: `translate(14px, -9px) scale(0.75)`, default
    transform: `translate(11px, 8px) scale(0.75)`,
  },
  //shrinked label on top, active
  '& label.Mui-focused': {
    color: 'rgba(0, 0, 0, 0.6)',
    // transform: `translate(14px, -9px) scale(0.75)`, default
    transform: `translate(12px, 9px) scale(0.75)`,
    // boxShadow: `inset 1px 2px 0px 0px rgba(125, 129, 159, 0.9)`,
  },
  //shrinked label opening on top
  '& legend': {
    marginLeft: '16px',
  },
  //////////////////////////
  // textfield
  //////////////////////////
  '& .MuiOutlinedInput-root': {
    // container for background and positioning
    paddingLeft: '16px',
    borderRadius: '3px',
    width: '100%',
    backgroundImage: `linear-gradient(
        to right,
        rgb(255, 255, 255),
        rgb(255, 255, 255)
      )`,
    transition: `all 0.1s ease`,
    border: '1px solid rgba(230, 230, 230, 1)',

    // content inside textfield
    '& fieldset': {
      border: 'none',
      borderColor: 'rgba(166, 87, 235, 0)',

      // boxShadow: `inset 0px 1px 0px 0px rgba(125, 129, 159, 0.9)`,
    },

    // content inside textfield, hover
    '&:hover fieldset': {
      // borderColor: 'rgba(166, 87, 235, 0)',
    },

    // content inside textfield, active
    '&.Mui-focused': {
      transform: `translate3d(1px, 1px, 0px)`,

      // boxShadow: `inset 1px 2px 0px 0px rgba(125, 129, 159, 0.9)`,
    },
    '&:active': {
      backgroundColor: 'transparent !important',
    },

    //textfield highlight border top layer (cover text)
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(166, 87, 235, 0)',
      //   boxShadow: `inset 0px 1px 0px 0px
      //       rgba(125, 129, 159, 0.9)`,
    },
  },

  //   '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
  //     // borderColor: 'rgba(166, 87, 235, 1)', // Adjust border color for focused state
  //     // backgroundColor: 'rgba(226, 207, 255, 0.5)',
  //   },
}
export const muiRippleStyles = {
  '& .MuiTouchRipple-child': {
    backgroundColor: 'rgba(226, 207, 255, 0) !important', // Set your desired ripple color here
  },
}
// export const textFieldStyles = {
//   style: {
//     paddingLeft: 16,
//     borderRadius: 100,
//     width: '360px',
//     backgroundImage: `linear-gradient(
//         to right,
//         rgb(232, 226, 234),
//         rgb(224, 225, 230)
//       )`,

//     // borderSpacing: 100,
//   },
// }

export const textAreaStyles = {
  //////////////////////////
  // textfield
  //////////////////////////
  '& .MuiOutlinedInput-root': {
    // container for background and positioning
    paddingLeft: '16px',
    borderRadius: '100px',
    width: '100%',
    backgroundImage: `linear-gradient(
      to right,
      rgb(232, 226, 234),
      rgb(224, 225, 230)
    )`,
    transition: `all 0.1s ease`,
    // content inside textfield
    '& fieldset': {
      border: 'none',
      borderColor: 'rgba(166, 87, 235, 0)',
      boxShadow: `inset 0px 1px 0px 0px rgba(125, 129, 159, 0.9)`,
    },
    // content inside textfield, hover
    '&:hover fieldset': {
      borderColor: 'rgba(166, 87, 235, 0)',
    },
    // content inside textfield, active
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(166, 87, 235, 0)',
      boxShadow: `inset 0px 2px 0px 0px rgba(125, 129, 159, 0.9)`,
    },
  },

  //   '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
  //     // borderColor: 'rgba(166, 87, 235, 1)', // Adjust border color for focused state
  //     // backgroundColor: 'rgba(226, 207, 255, 0.5)',
  //   },
}

export const dropdownOutlineLabelStyles = {
  //////////////////////////
  // label
  //////////////////////////

  //textfield highlight border top layer (cover text)
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  textAlign: 'left', // This aligns the selected text to the left
  '.MuiSelect-select': {
    textAlign: 'left',
    paddingLeft: '32px',
  },
}
export const dropdownItemStyles = {
  '&:hover': {
    backgroundColor: 'rgba(186, 97, 255, 1)', // Change background color on hover
    color: 'rgba(255, 255, 255, 1)', // Change text color on hover
  },

  paddingLeft: '16px',
  '&.Mui-selected': {
    backgroundColor: 'rgba(186, 97, 255, 0.15)',
    // color: 'rgba(255, 255, 255, 1)',
  },
  '&.Mui-selected:hover': {
    backgroundColor: 'rgba(186, 97, 255, 1)',
    color: 'rgba(255, 255, 255, 1)',
  },
}
