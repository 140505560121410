import axios, { AxiosError } from 'axios'
import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { toast } from 'react-toastify'

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_EOI_API_URL,
  // headers: {
  //   'Content-Type': 'application/json',
  // },
})

type Props = {
  children: JSX.Element
}

const errorHandler = (error: AxiosError) => {
  const statusCode = error.response?.status

  if (error.code === 'ERR_CANCELED') {
    return Promise.resolve()
  }

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error)
  }

  // hide 500 timeout error
  if (statusCode !== 500) {
    toast.error(error.message, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })
  }

  return Promise.reject(error)
}

const AxiosClient = ({ children }: Props) => {
  useEffect(() => {
    // console.log('add interceptors')
    const requestInterceptors = apiClient.interceptors.request.use(
      async (config) => {
        // Do something before request is sent
        // console.log('requestInterceptors', config)
        // const token = await getAccessTokenSilently()
        // config.headers.Authorization = `Bearer ${token}`
        return config
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error)
      },
    )

    const responseInterceptors = apiClient.interceptors.response.use(
      (response) => {
        console.log(
          'response from',
          response.request.responseURL,
          response.data,
        )

        if (response.data.errors?.length) {
          console.log(response.data.errors[0].message)
          return Promise.reject(response.data.errors[0].message)
        }
        return response
      },
      (error) => {
        console.log(
          'intercept response error',
          error.request.responseURL,
          error,
        )
        Sentry.captureException(error)
        return errorHandler(error)
      },
    )

    return () => {
      // console.log('remove interceptors')
      apiClient.interceptors.response.eject(requestInterceptors)
      apiClient.interceptors.response.eject(responseInterceptors)
    }
  }, [])
  return children
}

export default apiClient
export { AxiosClient }
