import ReactLoading from 'react-loading'
import React from 'react'

interface Props {
  className?: string
  size?: string
  color?: string
}

export const GenericLoadingIndicator: React.FC<Props> = ({
  className,
  ...props
}) => (
  <ReactLoading
    className={`top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 ${
      className || ''
    }`}
    type={'spinningBubbles'}
    color={`${props.color || '#000000'}`}
    height={`${props.size || '64px'}`}
    width={`${props.size || '64px'}`}
    {...props}
  />
)
