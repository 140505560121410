import { useDataContext } from '../context/DataProvider'
import styles from './GenericForm.module.css'
import { useEffect, useRef, useState } from 'react'
import { API } from 'apis/API'
import { CreateActivityDto, CreateSubmissionDto } from 'apis/models/API.dto'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Flicking, { MoveEndEvent } from '@egjs/react-flicking'
import '@egjs/react-flicking/dist/flicking.css'
import { Footer } from 'components/Footer'
import { LoadingIndicator } from 'components/LoadingIndicator'
import GenericFormWelcome from 'components/GenericFormWelcome'
import GenericFormThankYou from 'components/GenericFormThankYou'
import { Helmet } from 'react-helmet'
import DynamicConversations from 'components/DynamicConversations'
import { toastInfo } from 'utils/toast'
import { isCustomDomain, isDemoDomain, isProduction } from 'utils/EnvUtils'
import GenericFormWelcomeV2 from 'components/GenericFormWelcomeV2'
import GenericFormThankYouV2 from 'components/GenericFormThankYouV2'
import GenericFormWelcomeV3 from 'components/GenericFormWelcomeV3'
import Questions from 'components/Questions'

interface Props {
  clientCode: string
  programCode: string
}

export default function GenericForm(props: Props) {
  const navigate = useNavigate()
  const {
    submission,
    setSubmission,
    program,
    checkProgram,
    setProgramCode,
    setClientCode,
  } = useDataContext()
  const flicking = useRef<Flicking>(null)
  const location = useLocation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { subpage, programCode } = useParams()
  const clientCode = location.pathname.split('/')[1]

  useEffect(() => {
    let tmpClientCode = clientCode || window.location.pathname.split('/')[1]
    let tmpProgramCode = programCode || window.location.pathname.split('/')[2]

    // override from props
    if (props.clientCode) {
      tmpClientCode = props.clientCode
    }
    if (props.programCode) {
      tmpProgramCode = props.programCode
    }

    if (!tmpClientCode || !tmpProgramCode) {
      console.error('Client code or program code is missing')
    }
    console.log('GenericForm:', tmpClientCode, tmpProgramCode)
    checkProgram(tmpClientCode, tmpProgramCode)
  }, [checkProgram, clientCode, programCode, program, props])

  const goto = (path: string, replace: boolean = false) => {
    if (isDemoDomain) {
      navigate(`/${path}`, {
        replace: replace,
      })
    } else if (isCustomDomain()) {
      navigate(`/${programCode}/${path}`, {
        replace: replace,
      })
    } else {
      navigate(`/${clientCode}/${programCode}/${path}`, {
        replace: replace,
      })
    }
  }

  const submit = async () => {
    const dto: CreateSubmissionDto = {
      program: program!._id,
      firstName: submission?.firstName || '',
      email: submission?.email || '',
      ...Object.keys(submission || {}).reduce((acc, key) => {
        if (key !== 'program' && key !== 'firstName' && key !== 'email') {
          acc[key] = submission?.[key] || ''
        }
        return acc
      }, {} as Record<string, any>),
    }
    // lock, submit, unlock
    console.log('Submission:', dto)
    if (isSubmitting) {
      return
    }
    setIsSubmitting(true)
    await API.createOrUpdateSubmission(submission?._id, dto)
      .then((response) => {
        console.log('Response:', response)
        if (response) {
          console.log('Success')

          //log profile
          if (!isProduction && response?.profile) {
            let displayText = response?.profile.attributes
              .flatMap(
                (attribute: any) =>
                  `-${attribute.key}: ${attribute.value} - ${attribute.reason}`,
              )
              .join('\n')
            console.log('displayText:', displayText)
            toastInfo(displayText, 10000)
          }
          goto(`form/thankyou`, true)
        } else {
          console.log('Error')
        }
      })
      .catch((error) => {
        console.log('Error:', error)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const triggerNextPage = async (page: number) => {
    console.log('TriggerNextPage:', page)
    if (page === 1) {
      // log
      if (
        program?.components.sessionForm?.questions ||
        program?.queryContext?.questionSpaces
      ) {
        const dto: CreateActivityDto = {
          program: program!._id,
          ...Object.keys(submission || {}).reduce((acc, key) => {
            if (key !== 'program') {
              acc[key] = submission?.[key] || ''
            }
            return acc
          }, {} as Record<string, any>),
          action: 'start',
        }

        setIsSubmitting(true)
        await API.createActivity(dto)
          .then((response) => {
            console.log('Response:', response)
          })
          .catch((error) => {
            console.log('Error:', error)
          })
          .finally(() => {
            setIsSubmitting(false)
          })
        if (program?.components.sessionForm?.questions) {
          goto(`form/questions`)
        } else if (program?.queryContext?.questionSpaces) {
          goto(`form/conversations`)
        } else {
          console.error('No questions or questionSpaces')
          await submit()
        }
      } else {
        await submit()
      }
    } else if (page === 2) {
      await submit()
      goto(`form/thankyou`)
    }
  }

  const handleMoveEnd = (e: MoveEndEvent<Flicking>) => {
    // console.log('MoveEnd:', e)
  }

  // page changed
  useEffect(() => {
    if (subpage && !submission) {
      //landed on other form pages before init, redirect to form root
      goto(`form`)
      showPage(0)
    } //switch page
    else if (!subpage) {
      //url is form root, states are other subpages
      if (flicking.current?.index !== 0) {
        //user navigated to other pages ->
        //redirect to root and prevent browser back
        showPage(0)

        //navigate same page again, prevent browser forward
        goto(`form`)
      }
    } else if (subpage === 'questions') {
      showPage(1)
    } else if (subpage === 'conversations') {
      // console.log('conversations')
      showPage(1)
    } else if (subpage === 'thankyou') {
      // console.log('thankyou')
      // check if has questions or questionSpaces
      if (
        program?.components.sessionForm.questions ||
        program?.queryContext?.questionSpaces
      ) {
        showPage(2)
      } else {
        // no questions or questionSpaces, thank you page is index 1
        showPage(1)
      }
      console.log('clean data')
      setClientCode(undefined)
      setProgramCode(undefined)
      setSubmission(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]) //first trigger is slug undefined

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [location.pathname])

  const showPage = (page: number) => {
    flicking.current?.stopAnimation()
    flicking.current?.moveTo(page)
  }

  useEffect(() => {
    //dispatch resize event to flicking
    //delay 100ms to ensure flicking is ready
    // console.log('generic form: resize')
    setTimeout(() => {
      flicking.current?.resize()
    }, 100)

    //
  }, [submission])

  // console.log('questionSpaces:', program?.components.sessionForm.questionSpaces)

  if (!program) {
    return (
      <div className={styles.full}>
        <div
          style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            zIndex: '100000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingIndicator />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.full}>
      {program && program?.name && (
        <Helmet>
          <title>{program?.name}</title>
        </Helmet>
      )}
      <div className={styles.overlay} />
      <div className={styles.backgroundimage} />

      <div className={styles.full}>
        {isSubmitting && (
          <div
            style={{
              position: 'fixed',
              width: '100vw',
              height: '100vh',
              zIndex: '100000',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LoadingIndicator />
          </div>
        )}
        <div
          style={{
            backgroundImage: `url(${program?.components?.sessionForm?.bannerBackgroundImageUrl})`,
          }}
          className={styles.banner}
        >
          <div className={styles.bannercontent}>
            <h1 style={{ fontSize: '42px' }}>
              {program?.components?.sessionForm?.bannerTitle ||
                program?.name ||
                ''}
            </h1>
            <div style={{ marginTop: '16px' }}>
              <img src="/arrow-down-white.png" alt="down" />
            </div>
          </div>
        </div>

        <Flicking
          className={styles.container}
          align="prev"
          disableOnInit
          ref={flicking}
          adaptive={true}
          onMoveEnd={handleMoveEnd}
          autoResize={true}
          style={{
            backgroundImage: `url(${
              program?.components?.sessionForm?.formBackgroundImageUrl || ''
            })`,
          }}
        >
          <div className={styles.pagecontainer} id="form">
            {program?.components?.sessionForm?.version === 3 ? (
              <GenericFormWelcomeV3 onContinue={() => triggerNextPage(1)} />
            ) : program?.components?.sessionForm?.version === 2 ? (
              <GenericFormWelcomeV2 onContinue={() => triggerNextPage(1)} />
            ) : (
              <GenericFormWelcome onContinue={() => triggerNextPage(1)} />
            )}
          </div>
          {program?.components?.sessionForm?.questions && (
            <div className={styles.pagecontainer} id="questions">
              <Questions
                questions={program?.components?.sessionForm?.questions || []}
                onContinue={() => triggerNextPage(2)}
              />
            </div>
          )}
          {program?.queryContext?.questionSpaces && (
            <div className={styles.pagecontainer} id="conversations">
              <DynamicConversations onContinue={() => triggerNextPage(2)} />
            </div>
          )}
          <div className={styles.pagecontainer} id="thankyou">
            {program?.components?.sessionForm?.version === 2 ? (
              <GenericFormThankYouV2 />
            ) : (
              <GenericFormThankYou />
            )}
          </div>
        </Flicking>
        <Footer backgroundColor="#000" />
      </div>
    </div>
  )
}
