import React from 'react'
import styles from './GenericImageBox.module.css'

interface Props {
  title: string
  titleColor: string
  imageUrl: string
  background: string
}

export const GenericImageBox: React.FC<Props> = ({
  title,
  titleColor,
  imageUrl,
  background,
}) => {
  return (
    <>
      <img
        src={background}
        alt="background"
        style={{ position: 'absolute', width: '100%' }}
      />
      <div className={styles.container}>
        <div className={styles.content}>
          <span
            className={styles.title}
            style={{
              color: titleColor,
            }}
          >
            {title}
          </span>
          <img src={imageUrl} alt="All logos" width={'100%'} />
        </div>
      </div>
    </>
  )
}
