import * as React from 'react'
import Drawer from '@mui/material/Drawer'
import styles from './GenericAppBar.module.css'
import { useNavigate } from 'react-router-dom'

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
  data?: {
    items: string[]
    buttonText: string
    clientLogo?: {
      url: string
      link: string
      alt: string
      width: string
    }
    menuItems: [
      {
        item: string
        subitems: [string]
      },
    ]
  }
}

export default function GenericAppBar(props: Props) {
  const { window, data } = props
  const items = data?.items || []
  const buttonText = data?.buttonText || ''
  const clientLogo = data?.clientLogo || { url: '', alt: '', width: '' }

  const [mobileOpen, setMobileOpen] = React.useState(false)
  const navigate = useNavigate()

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }
  const handleMenuItemClick = (item: string) => {
    console.log('clicked', item)
    const id = item.toLowerCase().replace(' ', '-')
    const section = document.getElementById(id)
    if (section) {
      section.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }
  }

  const drawer = (
    <div onClick={handleDrawerToggle} className={styles.mobileitems}>
      {items.map((item) => (
        <button
          key={item}
          className={styles.mobileitem}
          onClick={() => handleMenuItemClick(item)}
        >
          {item}
        </button>
      ))}
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  const handleCTAClicked = (): void => {
    navigate('form')
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logos}>
          <a href="https://www.mentem.co">
            <img src="/applogo.svg" className={styles.logo} alt="mentem logo" />
          </a>
          <div className={styles.logo}>
            <a href={data?.clientLogo?.link} target="_blank" rel="noreferrer">
              <img
                src={clientLogo?.url}
                width={clientLogo?.width}
                alt={clientLogo?.alt}
              />
            </a>
          </div>
        </div>
        <div className={styles.desktopitems}>
          {items.map((item) => (
            <button
              key={item}
              className={styles.desktopitem}
              onClick={() => handleMenuItemClick(item)}
            >
              {item}
            </button>
          ))}
          {buttonText && (
            <button className={styles.cta} onClick={handleCTAClicked}>
              {buttonText}
            </button>
          )}
        </div>

        <button className={styles.menubutton} onClick={handleDrawerToggle}>
          <img src="/menu.png" alt="menu"></img>
        </button>
      </div>

      {/* mobile menu */}
      <nav>
        <Drawer
          anchor="top"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            transform: 'translateY(90px)',

            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '100%',
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </div>
  )
}
