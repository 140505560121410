import React from 'react'
import styles from './SessionCTA.module.css'
import { Link } from 'react-router-dom'

export const SessionCTA: React.FC = () => {
  const title = 'Get started'
  const subtitle = 'Tell us about yourself'

  return (
    <div id="eoi" className={styles.container}>
      <Link to="form">
        <div className={styles.frame}>
          <div className={styles.bg}></div>
          <div className={styles.content}>
            <h1 className={styles.title}>{title}</h1>
            <p className={styles.subtitle}>
              <b>{subtitle}</b>
            </p>
            <br></br>
            <div className={styles.description}>
              <p>
                Express your interest in the program by completing a short form.{' '}
              </p>
              It only takes <b>5 minutes</b> to share your hopes for the course
              and help us gauge your level. <br />
              <b>Click below to get started.</b>
            </div>

            <button className={styles.cta}>Apply now</button>
          </div>
        </div>
      </Link>
    </div>
  )
}
