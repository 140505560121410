import React from 'react'
import styles from './SessionHero.module.css'
import { useMediaQuery } from '@mui/material'

interface Props {
  title: string
  subtitle: string
  background: string
  backgroundMobile: string
  titleColor: string
  subtitleColor: string
  titleSize: string
  subtitleSize: string
}

export const SessionHero: React.FC<Props> = ({
  title,
  subtitle,
  background,
  backgroundMobile,
  titleColor,
  subtitleColor,
  titleSize,
  subtitleSize,
}) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url('${isMobile ? backgroundMobile : background}')`,
        backgroundPosition: '100% 50%',
      }}
    >
      <div className={styles.content}>
        <h1
          className={styles.title}
          style={{
            color: titleColor,
            fontSize: titleSize,
          }}
        >
          {title.split('\n').map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </h1>
        <p className={styles.description}>
          <b>
            {!isMobile && (
              <span style={{ color: subtitleColor, fontSize: subtitleSize }}>
                {subtitle.split('\n').map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </span>
            )}
          </b>
        </p>
        <img src="/arrow-down-black.png" alt="arrow-down" className={styles.arrow} />
      </div>
    </div>
  )
}
