import { useEffect } from 'react'
import styles from './OptionsForRla.module.css'
import { useDataContext } from 'context/DataProvider'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'

type PageProps = {
  onContinue: () => void
  options: string[]
}

export default function OptionsForRla({ onContinue, options }: PageProps) {
  const { submission, setSubmission } = useDataContext()

  const handleClickContinue = () => {
    onContinue()
  }

  useEffect(() => {
    if (!submission?.questions) {
      setSubmission({ ...submission!, questions: [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p style={{ textAlign: 'center', fontSize: '26px', fontWeight: '700' }}>
          Program dates
        </p>
        <div className={styles.main}>
          <div className={styles.question}>
            Which of the following course dates would you like to apply for?
            <br />
            (You can select multiple dates)
          </div>

          <div className={styles.checkboxcontainer}>
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={submission?.dates?.includes(option) ? true : false}
                    sx={{
                      '&.Mui-checked': {
                        color: '#8064AF',
                      },
                    }}
                    onChange={(e) => {
                      setSubmission({
                        ...submission!,
                        dates: e.target.checked
                          ? [...(submission?.dates || []), option]
                          : (submission?.dates || []).filter(
                              (d) => d !== option,
                            ),
                      })
                    }}
                  />
                }
                label={<Typography sx={{ fontSize: 14 }}>{option}</Typography>}
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 24 },
                }}
              />
            ))}
          </div>
        </div>
        <button
          className={`${styles.submitbutton}`}
          onClick={handleClickContinue}
        >
          Submit
        </button>
      </div>
    </div>
  )
}
