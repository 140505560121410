// import { useEffect } from 'react'
// import { useDataContext } from './DataProvider'
// import { useParams } from 'react-router-dom'

type Props = {
  children: JSX.Element
}

const Preloader = ({ children }: Props) => {
  // const { checkProgram } = useDataContext()
  // const { clientCode, programCode } = useParams()

  // useEffect(() => {
  //   console.log('App:', clientCode, programCode)
  //   checkProgram(clientCode, programCode)
  // }, [clientCode, programCode, checkProgram])

  return children
}

export { Preloader }
