import React from 'react'
import styles from './GenericSessionHero.module.css'
import { useMediaQuery } from '@mui/material'
import { ReactComponent as ArrowDown } from 'images/arrow-down.svg'

interface Props {
  title: string
  subtitle: string
  background: string
  color?: string
}

export const GenericSessionHero: React.FC<Props> = ({
  title,
  subtitle,
  background,
  color,
}) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <div className={styles.container}>
      <div
        className={styles.heroImage}
        style={{
          backgroundImage: `url('${background}')`,
        }}
      >
        <div className={styles.heroLogo}>
          <div className={styles.heroText}>
            <h1
              className={styles.title}
              style={{ color: color ? color : 'rgba(255, 255, 255, 1)' }}
            >
              {title}
            </h1>
            <p className={styles.description}>
              <b>
                {!isMobile && (
                  <span
                    style={{ color: color ? color : 'rgba(255, 255, 255, 1)' }}
                  >
                    {subtitle.split('\n').map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </span>
                )}
              </b>
            </p>
            <ArrowDown stroke={color ? color : '#ffffff'} />
          </div>
        </div>
      </div>
    </div>
  )
}
