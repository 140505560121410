import React from 'react'
import styles from './SessionBlocksForRla.module.css'
import { useMediaQuery } from '@mui/material'
import axios from 'axios'

interface Props {
  title: string
  description: string
  brochureThumbnail: string
  brochurePdf: string
  syllabusPdf: string
  topics: any[]
}

export const SessionBlocksForRla: React.FC<Props> = ({
  title,
  description,
  brochureThumbnail,
  brochurePdf,
  syllabusPdf,
  topics
}: Props) => {
  const isMobile = useMediaQuery('(max-width: 767px)')
  const background = ''
  const items = topics

  const handleClick = (link: string, url: string, title: string) => {
    localStorage.setItem(link, JSON.stringify({ url, title }))
    window.open(link, '_blank')
  }

  const handleDownload = async (fileUrl: string) => {
    try {
      const urlParts = fileUrl.split('/')
      const fileName = urlParts[urlParts.length - 1]

      console.log('Downloaded file:', fileName)

      const response = await axios.get(fileUrl, {
        responseType: 'blob', // Important for binary data
      })

      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]))

      // Create a link element
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName) // Use extracted filename
      document.body.appendChild(link)
      link.click()

      // Clean up and remove the link
      link.remove()
      window.URL.revokeObjectURL(url) // Free up memory
    } catch (error) {
      console.error('Error downloading the file:', error)
    }
  }

  return (
    <div
      id="demo-content"
      className={styles.container}
      style={{ backgroundImage: `url('${background}')` }}
    >
      <div className={styles.content}>
        <div
          style={{
            fontSize: '25px',
            fontWeight: 700,
            color: '#282978',
            marginBottom: '32px',
          }}
        >
          {title}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '36px',
          }}
        >
          <div
            style={{
              width: '220px',
              paddingLeft: '16px',
              paddingRight: '16px',
            }}
          >
            <img
              src={brochureThumbnail}
              alt="RLA data analytics"
              style={{ width: '100%' }}
            ></img>
          </div>
          <div
            style={{
              width: '320px',
              fontSize: '20px',
              fontWeight: 'bold',
              textAlign: 'left',
            }}
          >
            <div>
              {description.split('\n').map((item, key) => {
                return (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                )
              })}
            </div>
            <button
              style={{ minWidth: '226px' }}
              className={styles.downloadcta}
              onClick={() => handleDownload(brochurePdf)}
            >
              Program brochure
            </button>
            <button
              style={{ minWidth: '226px' }}
              className={styles.downloadcta}
              onClick={() => handleDownload(syllabusPdf)}
            >
              Syllabus
            </button>
          </div>
        </div>

        <div style={{ marginTop: '40px' }}>
          <p className={styles.description}>Demo topics</p>

          <div className={styles.boxesstack}>
            {items.map((item, index) =>
              !isMobile ? (
                <div key={index} className={styles.block}>
                  <img
                    className={styles.itemimage}
                    src={item.image}
                    alt={item.title}
                  />
                  <div className={styles.itemtitle}>{item.title}</div>
                  <button
                    className={styles.itemcta}
                    onClick={() => handleClick(item.link, item.url, item.title)}
                  >
                    {item.cta}
                  </button>
                </div>
              ) : (
                <div key={index} className={styles.block2}>
                  <div className={styles.blockcontainer}>
                    <div className={styles.frame}>
                      <div className={styles.itemtitle}>{item.title}</div>

                      <button
                        className={styles.itemcta}
                        onClick={() =>
                          handleClick(item.link, item.url, item.title)
                        }
                      >
                        {item.cta}
                      </button>
                    </div>
                  </div>
                  <img
                    className={styles.itemimage}
                    src={item.image}
                    alt={item.title}
                  />
                </div>
              ),
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
