import * as React from 'react'
import Drawer from '@mui/material/Drawer'
import styles from './AppBarForRla.module.css'
import { useNavigate } from 'react-router-dom'

interface Props {
  window?: () => Window
}

const navItems = ['Videos', 'Program structure', 'FAQs']
const cta = 'Apply now'

export default function AppBarForRla(props: Props) {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const navigate = useNavigate()

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }
  const handleMenuItemClick = (item: string) => {
    console.log('clicked', item)
    const id = item.toLowerCase().replace(' ', '-')
    const section = document.getElementById(id)
    if (section) {
      section.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }
  }

  const drawer = (
    <div onClick={handleDrawerToggle} className={styles.mobileitems}>
      {navItems.map((item) => (
        <button
          key={item}
          className={styles.mobileitem}
          onClick={() => handleMenuItemClick(item)}
        >
          {item}
        </button>
      ))}
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  const handleCTAClicked = (): void => {
    navigate('form')
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logos}>
          <a href="https://www.mentem.co">
            <img src="/applogo.svg" className={styles.logo} alt="mentem logo" />
          </a>
          <div className={styles.logoRla}>
            <img
              src="https://content.mentem.co/eoi/rla/logo-rla-large.png"
              width={'100%'}
              alt="resolution life logo"
            />
          </div>
        </div>
        <div className={styles.desktopitems}>
          {navItems.map((item) => (
            <button
              key={item}
              className={styles.desktopitem}
              onClick={() => handleMenuItemClick(item)}
            >
              {item}
            </button>
          ))}
          <button className={styles.cta} onClick={handleCTAClicked}>
            {cta}
          </button>
        </div>

        <button className={styles.menubutton} onClick={handleDrawerToggle}>
          <img src="/menu.png" alt="menu"></img>
        </button>
      </div>

      {/* mobile menu */}
      <nav>
        <Drawer
          anchor="top"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            transform: 'translateY(90px)',

            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '100%',
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </div>
  )
}
