import { useDataContext } from '../context/DataProvider'
import styles from './HomeForRla.module.css'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import '@egjs/react-flicking/dist/flicking.css'
import { Footer } from 'components/Footer'
import { SessionDuplex } from 'components/SessionDuplex'
import { Helmet } from 'react-helmet'
import AppBarForRla from 'components/AppBarForRla'
import { SessionCTAForRla } from 'components/SessionCTAForRla'
import { SessionScheduleForRla } from 'components/SessionScheduleForRla'
import { SessionScheduleTableForRla } from 'components/SessionScheduleTableForRla'
import { SessionBlocksForRla } from 'components/SessionBlocksForRla'
import { SessionBoxesForRla } from 'components/SessionBoxesForRla'
import { SessionFaqForRla } from 'components/SessionFaqForRla'
import { SessionHeroForRla } from 'components/SessionHeroForRla'
import { GenericLoadingIndicator } from 'components/GenericLoadingIndicator'

export default function HomeForRla() {
  const { checkProgram, program } = useDataContext()
  const { clientCode, programCode } = useParams()

  useEffect(() => {
    const tmpClientCode = clientCode || 'rla'
    if (!tmpClientCode || !programCode) return
    checkProgram(tmpClientCode, programCode)
  }, [checkProgram, clientCode, programCode])

  return (
    <div id="main" className={styles.container}>
      {program && program?.name && (
        <Helmet>
          <title>{program?.name}</title>
        </Helmet>
      )}

      <AppBarForRla />
      {program && (
        <>
          <div id="content" style={{ position: 'relative' }}>
            <SessionHeroForRla
              title={program?.components?.sessionHero.title || ''}
              subtitle={program?.components?.sessionHero.subtitle || ''}
              background={program?.components?.sessionHero.background || ''}
              titleColor="#000"
              subtitleColor="#7F64AF"
              titleSize="36px"
              subtitleSize="18px"
            />
            <SessionCTAForRla />

            <SessionDuplex
              id="videos"
              backgroundColor="#8064B0"
              title={program?.components?.sessionVideo.title || ''}
              description={program?.components?.sessionVideo.description || ''}
              titleColor="rgba(255,255,255,1)"
              descriptionColor="rgba(255,255,255,1)"
              image={program?.components?.sessionVideo.videoThumbnail || ''}
              videoUrl={program?.components?.sessionVideo.videoUrl || ''}
            />

            <img
              src="https://content.mentem.co/eoi/rla/top-rla.jpg"
              alt="top"
              style={{ position: 'absolute', width: '100%' }}
            />

            <SessionScheduleForRla
              titleColor="rgba(0,0,0,1)"
              backgroundNumberColor="#0A1F48"
              numberColor="rgba(255,255,255,1)"
              numberSize="36px"
              closeDateTextColor="#282978"
              closeDateColor="rgba(0,0,0,1)"
              progressBorderColor="#282978"
              progressBarColor="#0A1F48"
              upcomingProgramsColor="rgba(0, 0, 0, 1)"
              upcomingPrograms={
                program?.components?.sessionSchedule?.upcomingPrograms || []
              }
            />

            <SessionScheduleTableForRla
              backgroundColor="#0A1F48"
              clientLogo="https://content.mentem.co/eoi/rla/logo-rla-large.png"
              head={program?.components?.sessionScheduleTable.head || {}}
              rows={program?.components?.sessionScheduleTable.rows || []}
            />

            <SessionBlocksForRla
              title={program?.components?.sessionBrochure?.title || ''}
              description={
                program?.components?.sessionBrochure?.description || ''
              }
              brochureThumbnail={
                program?.components?.sessionBrochure?.brochureThumbnail || ''
              }
              brochurePdf={
                program?.components?.sessionBrochure?.brochurePdf || ''
              }
              syllabusPdf={
                program?.components?.sessionBrochure?.syllabusPdf || ''
              }
              topics={program?.components?.sessionDemoTopics.items || []}
            />
          </div>

          <SessionBoxesForRla />

          <img
            src="https://content.mentem.co/eoi/rla/bottom-rla.png"
            alt="bottom"
            style={{
              position: 'absolute',
              width: '100%',
              bottom: '210px',
              zIndex: -1,
            }}
          />
          <SessionFaqForRla
            items={program?.components?.sessionFaq.items || []}
            cta={program?.components?.sessionFaq?.cta}
          />
          <Footer backgroundColor="#0B2048" />
        </>
      )}
      {!program && (
        <div className={styles.center}>
          <GenericLoadingIndicator />
        </div>
      )}
    </div>
  )
}
