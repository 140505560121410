import { useDataContext } from 'context/DataProvider'
import styles from './GenericFormThankYou.module.css'
import { useNavigate } from 'react-router-dom'

export default function GenericFormThankYou() {
  const navigate = useNavigate()
  const { program } = useDataContext()

  const handleClickContinue = () => {
    // back to home page by program slug
    if (program) {
      navigate(`/${program.slug}`)
      return
    } else {
      navigate('/')
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.main}>
          <img
            src="/images/charm-circle-tick.png"
            alt="tick"
            className={styles.img}
          />
          <div className={styles.title}>Thank you for your submission!</div>
          <div className={styles.item}>
            We appreciate your interest in our data analytics essentials course.
            Our team will review your information and get back to you soon to
            finalise arrangements.
          </div>
          <div className={styles.item}>
            <span>
              If you have any questions about this course or other Mentem
              programs, please reach out to{' '}
              <span style={{ color: 'rgba(186, 97, 255, 1)' }}>
                <a href="mailto:hello@mentem.co">hello@mentem.co</a>
              </span>
            </span>
          </div>
        </div>
        <button onClick={handleClickContinue} className={styles.submitbutton}>
          OK
        </button>
      </div>
    </div>
  )
}
