import React, { useEffect, useRef } from 'react'
import styles from './GenericSessionSwiper.module.css'
import { register } from 'swiper/element/bundle'
import { Navigation } from 'swiper/modules'
import { Testimonial } from 'apis/models/testimonial.entity'
import { ReactComponent as ArrowRight } from 'images/arrow-right.svg'
import { ReactComponent as ArrowLeft } from 'images/arrow-left.svg'
import { useMediaQuery } from '@mui/material'

register()

interface Props {
  id: string
  title: string
  items: Testimonial[]
}

export const GenericSessionSwiper: React.FC<Props> = ({ id, title, items }) => {
  const swiperElRef = useRef<any>(null)
  const isMobile = useMediaQuery('(max-width: 767px)')

  useEffect(() => {
    // listen for Swiper events using addEventListener
    swiperElRef.current?.addEventListener('swiperprogress', (e: any) => {
      const [swiper, progress] = e.detail
      console.log(progress, swiper)
    })

    swiperElRef.current?.addEventListener('swiperslidechange', (e: any) => {
      console.log('slide changed')
    })
  }, [])

  // add a timer to change the slide every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      swiperElRef.current?.swiper.slideNext()
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div id={id} className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.swiperButtons}>
        <div className={styles.buttonsContainer}>
          <button
            type="button"
            title="Previous"
            onClick={() => swiperElRef.current?.swiper.slidePrev()}
          >
            <ArrowLeft />
          </button>
          <button
            type="button"
            title="Next"
            onClick={() => swiperElRef.current?.swiper.slideNext()}
          >
            <ArrowRight />
          </button>
        </div>
      </div>
      <swiper-container
        ref={swiperElRef}
        slides-per-view={isMobile ? 1.2 : 3.2}
        modules={[Navigation]}
        navigation={false}
        pagination={false}
        loop={true}
        spaceBetween={20}
        slidesPerView={1.1}
      >
        {items.map((testimonial, index) => (
          <swiper-slide key={index}>
            <div className={styles.card}>
              <div className={styles.content}>
                <span>“{testimonial.content}”</span>
              </div>
              <div className={styles.name}>
                <span style={{ fontWeight: 'bold' }}>{testimonial.name}</span>
                <span> | {testimonial.position}</span>
              </div>
            </div>
          </swiper-slide>
        ))}
      </swiper-container>
    </div>
  )
}
