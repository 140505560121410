import React, { useEffect } from 'react'
import styles from './SessionScheduleTable.module.css'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material'
import Collapse from '@mui/material/Collapse'

interface Props {
  backgroundColor: string
  clientLogo: {
    url: string
    alt: string
    width: string
  }
  head: any
  rows: any[]
}

export const SessionScheduleTableForNetm: React.FC<Props> = ({
  backgroundColor,
  clientLogo,
  head,
  rows,
}) => {
  const [showDetails, setShowDetails] = React.useState(false)
  const isMobile = useMediaQuery('(max-width: 767px)')

  useEffect(() => {
    // Collapse the details when the screen size is mobile
    if (isMobile) {
      setShowDetails(false)
    }
  }, [isMobile])

  return (
    <div id="program-structure" className={styles.container}>
      <div style={{ fontSize: isMobile ? '30px' : '48px', fontWeight: 'bold' }}>
        Microcredential structure
      </div>
      <div
        style={{
          marginTop: '24px',
          fontSize: '25px',
          fontWeight: 'bold',
          color: '#000',
        }}
      >
        Schedule
      </div>
      <div
        style={{
          marginTop: '40px',
          width: '100%',
          paddingLeft: isMobile ? '0px' : '60px',
          paddingRight: isMobile ? '0px' : '60px',
        }}
      >
        <div
          style={{
            backgroundColor: backgroundColor,
            color: 'rgba(255,255,255,1)',
            width: '100%',
            paddingTop: '20px',
            paddingLeft: isMobile ? '8px' : '48px',
            paddingRight: isMobile ? '8px' : '48px',
            borderRadius: '20px',
          }}
        >
          {/* logos  */}
          <div
            className={styles.logos}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: isMobile ? 'column' : 'row',
            }}
          >
            <div style={{ flex: 1 }}>
              <img
                src={clientLogo.url}
                alt={clientLogo.alt}
                width={clientLogo.width}
              />
            </div>
            <div style={{ flex: 1 }}>
            <img
                src="/mentem.png"
                alt="mentem"
                style={{ width: '270px', padding: '20px' }}
              />
            </div>
            <div style={{ flex: 1 }} />
          </div>
          <div>
            <h1 style={{ paddingBottom: '20px' }}>Sample schedule</h1>
            <Collapse in={showDetails}>
              <TableContainer className={styles.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {Object.keys(head).map((key, index) => (
                        <TableCell
                          key={index}
                          className={index % 2 === 0 ? styles.dark : ''}
                          align="center"
                        >
                          <span
                            style={{
                              fontFamily: 'Inter',
                              fontSize: '16px',
                              fontWeight: 700,
                            }}
                          >
                            {head[key]}
                          </span>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {Object.keys(row).map((key, index) => (
                          <TableCell
                            key={index}
                            className={index % 2 === 1 ? styles.dark : ''}
                            align="center"
                            style={{
                              fontFamily: 'Roboto',
                              fontSize: '14px',
                              fontWeight: 600,
                              color:
                                rowIndex === rows.length - 1 &&
                                index === Object.keys(row).length - 1
                                  ? 'rgba(186, 97, 255,1)'
                                  : 'rgba(0,0,0,0.87)',
                              padding: '6px',
                            }}
                          >
                            {row[key]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </div>
          <div
            onClick={() => setShowDetails(!showDetails)}
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              padding: '24px',
              cursor: 'pointer',
            }}
          >
            {showDetails ? 'Hide details —' : 'Show details ＋'}
          </div>
        </div>
      </div>
    </div>
  )
}
