import { useRef } from 'react'
import styles from './ThankYou.module.css'
import { useNavigate } from 'react-router-dom'

const ThankYou = () => {
  const navigate = useNavigate()

  const shouldValidate = useRef(false)

  const handleClickContinue = () => {
    shouldValidate.current = true
    navigate('/cba/advanced-analytics')
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 style={{ textAlign: 'center', fontSize: '24px' }}>
          Here's what happens next
        </h1>
        <div className={styles.main}>
          <div className={styles.item}>
            <span>
              You will receive an email invitation to take a short cognitive
              assessment as part of the application process. <br />
              The email will come from{' '}
              <span style={{ color: 'rgba(186, 97, 255, 1)' }}>
                do-not-reply@criteriacorp.com
              </span>
              .
              <br />
              You should receive this email within the next 24 hours.
            </span>
          </div>
          <div className={styles.item}>
            After completing the assessment, your application will be reviewed
            by CBA and Mentem.
          </div>
          <div className={styles.item}>
            <span>
              If you have any questions about this test or do not receive an
              invitation to the cognitive test, please reach out to{' '}
              <span style={{ color: 'rgba(186, 97, 255, 1)' }}>
                <a href="mailto:help@mentem.co">help@mentem.co</a>
              </span>
              .
            </span>
          </div>
        </div>
        <button onClick={handleClickContinue} className={styles.submitbutton}>
          OK
        </button>
      </div>
    </div>
  )
}

export default ThankYou
