import { useRef, useState, useEffect, useCallback } from 'react'
import styles from './EmailVerification.module.css'
import { TextField } from '@mui/material'
import { isEmail } from 'utils/stringUtils'
import { textFieldOutlineLabelStyles } from 'utils/MuiStyles'
import { useNavigate, useParams } from 'react-router-dom'
import { API } from 'apis/API'
import { useDataContext } from '../context/DataProvider'
import { LoadingIndicator } from './LoadingIndicator'

interface Props {
  onLoading(loading: boolean): void
}

export default function EmailVerification({ onLoading }: Props) {
  const { setSubmission, program } = useDataContext()
  const navigate = useNavigate()
  const shouldValidate = useRef(false)
  const refEmail = useRef<HTMLInputElement>(null)
  const [email, setEmail] = useState('')
  const [isLocked, setIsLocked] = useState(false)
  const [isFailedToken, setIsFailedToken] = useState(false)
  const { clientCode, programCode } = useParams()
  const [showError, setShowError] = useState(false)
  const token = new URLSearchParams(window.location.search).get('token')
  const [isValidating, setIsValidating] = useState(true)
  const [buttonColor, setButtonColor] = useState('rgba(186, 97, 255, 1)')
  // for client code in url
  const clientCodeFromPath = window.location.pathname.split('/')[1]

  const handleClickContinue = async () => {
    shouldValidate.current = true

    // Validate email
    setShowError(false)
    const emailError = getEmailErrorMessage()
    if (emailError) {
      setShowError(true)
      return
    }

    try {
      onLoading(true)
      // token is from url param
      if (!token) {
        alert('Token is missing')
        return
      }
      const response = await API.getSubmissionByToken(token, email)
      if (response === undefined) {
        setIsLocked(true)
      } else {
        setSubmission(response)
        const tmpClientCode = clientCode || clientCodeFromPath
        // console.log('Form:', tmpClientCode, programCode)
        navigate(`/${tmpClientCode}/${programCode}/form`)
      }
    } catch (e) {
      setIsLocked(true)
    } finally {
      onLoading(false)
    }
  }

  const handleClickOk = () => {
    navigate('/')
  }

  const getEmailErrorMessage = () => {
    if (!shouldValidate.current) {
      return ''
    }

    const testEmail = email.trim()
    if (!isEmail(testEmail)) {
      return 'Please input your business email address'
    }
    return ''
  }

  const validateToken = useCallback(async () => {
    if (token) {
      try {
        setIsValidating(true)
        const res = await API.validateToken(token)
        setIsLocked(!res.success)
      } catch (e) {
        setIsLocked(true)
        // old token
        const statusCode = (e as any).response.status
        setIsFailedToken(statusCode === 404)
      } finally {
        setIsValidating(false)
      }
    }
  }, [token])

  useEffect(() => {
    validateToken()
  }, [validateToken])

  useEffect(() => {
    if (program) {
      setButtonColor(program.components.sessionForm.buttonBackgroundColor)
    }
  }, [program])

  return (
    <div className={styles.container}>
      {isValidating && (
        <div
          style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            zIndex: '100000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingIndicator />
        </div>
      )}
      {!isValidating && (
        <div className={styles.content}>
          {isLocked && (
            <div className={styles.main}>
              {isFailedToken && (
                <h1 style={{ textAlign: 'left', fontSize: '24px' }}>
                  It looks like you are using an old link. Please check your
                  email inbox and use the link in your most recent email from
                  Mentem. If you are having issues accessing your answers, you
                  can contact <a href="mailto:help@mentem.co">help@mentem.co</a>
                </h1>
              )}
              {!isFailedToken && (
                <h1 style={{ textAlign: 'left', fontSize: '24px' }}>
                  Sorry, the email address you entered does not match the link
                  provided. For security purposes, this link will be locked for
                  30 minutes after each unsuccessful attempt. Please try again
                  later, or contact{' '}
                  <a href="mailto:help@mentem.co">help@mentem.co</a> if you are
                  still unable to access.
                </h1>
              )}
              <button
                onClick={handleClickOk}
                className={styles.submitbutton}
                style={{
                  backgroundColor: `${buttonColor}`,
                }}
              >
                Ok
              </button>
            </div>
          )}
          {!isLocked && (
            <>
              <h1 style={{ textAlign: 'left', fontSize: '24px' }}>
                Please verify your email before proceeding to review and edit
                your submission
              </h1>
              <div className={styles.main}>
                <TextField
                  inputRef={refEmail}
                  label={getEmailErrorMessage() || 'Business email'}
                  variant="outlined"
                  sx={textFieldOutlineLabelStyles}
                  error={showError}
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />
              </div>

              <button
                onClick={handleClickContinue}
                className={styles.submitbutton}
                style={{
                  backgroundColor: `${buttonColor}`,
                }}
              >
                Continue
              </button>
            </>
          )}
        </div>
      )}
    </div>
  )
}
