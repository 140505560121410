import { useEffect } from 'react'
import styles from './Questions.module.css'
import { useDataContext } from 'context/DataProvider'
import { TextareaAutosize } from '@mui/material'

type PageProps = {
  onContinue: () => void
  questions: any[]
}

const Questions = ({ onContinue, questions }: PageProps) => {
  const { submission, setSubmission, program } = useDataContext()

  const handleClickContinue = () => {
    onContinue()
  }

  useEffect(() => {
    if (!submission?.questions || submission?.questions.length === 0) {
      setSubmission({ ...submission!, questions: questions })
    }
  }, [questions, setSubmission, submission])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 style={{ textAlign: 'center' }}>{program?.name}</h1>

        {submission?.questions?.map((q) => (
          <div key={q.id}>
            <div className={styles.question}>{q.question}</div>
            <TextareaAutosize
              className={`${styles.textarea}`}
              placeholder={q.placeholder}
              minRows={3}
              defaultValue={q.answer}
              onChange={(e) => {
                const updatedQuestions = submission?.questions!.map(
                  (question) =>
                    question.id === q.id
                      ? { ...question, answer: e.target.value }
                      : question,
                )
                setSubmission({ ...submission!, questions: updatedQuestions })
              }}
            />
          </div>
        ))}

        <button
          className={`${styles.submitbutton}`}
          onClick={handleClickContinue}
        >
          Continue
        </button>
      </div>
    </div>
  )
}

export default Questions
