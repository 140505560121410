import styles from './EmailConfirmation.module.css'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSearchParams } from 'react-router-dom'
import { GenericLoadingIndicator } from 'components/GenericLoadingIndicator'
import { API } from 'apis/API'
import confirmImage from 'images/confirm.png'
import cancelImage from 'images/cancel.png'
import questionImage from 'images/question.png'
import failSvg from 'images/fail.svg'
import backgroundImage from 'images/background-email-manager.svg'
import backgroundImageMobile from 'images/background-email-manager-mobile.svg'
import backgroundImageCandidate from 'images/background-email-candidate.jpg'
import backgroundImageCandidateMobile from 'images/background-email-candidate-mobile.jpg'
import { useMediaQuery } from '@mui/material'

const commonError = 'Failed to confirm email.'

enum ConfirmationText {
  // learner
  LearnerConfirmed = 'Thank you for confirming your enrolment. We will email you before the program starts with more information and calendar invitations.',
  LearnerCancelled = 'You have confirmed you will not enrol in this program.',
  LearnerConfirmedAlready = 'You have already confirmed your enrolment. We will email you before the program starts with more information and calendar invitations.',
  LearnerCancelledAlready = 'You have already confirmed you will not enrol in this program.',
  LearnerChangeConfirmToCancel = 'You have previously confirmed your enrolment.',
  LearnerChangeConfirmToCancelQuestion = 'Are you sure you want to cancel your enrolment? ',
  LearnerChangeCancelToConfirm = 'You have previously confirmed you will not enrol in the program.',
  LearnerChangeCancelToConfirmQuestion = 'Are you sure you want to change your decision and enrol?',
  // manager
  ManagerConfirmed = 'Thank you for confirming your approval of your team member’s enrolment.',
  ManagerCancelled = 'You have confirmed you do not approve your team member’s enrolment in this program.',
  ManagerConfirmedAlready = 'You have already confirmed your approval of your team member’s enrolment.',
  ManagerCancelledAlready = 'You have already confirmed you do not approve your team member’s enrolment in this program.',
  ManagerChangeConfirmToCancel = 'You have previously confirmed your approval of your team member’s enrolment.',
  ManagerChangeConfirmToCancelQuestion = 'Are you sure you want to cancel your approval?',
  ManagerChangeCancelToConfirm = 'You have previously confirmed you do not approve your team member’s enrolment in this program.',
  ManagerChangeCancelToConfirmQuestion = 'Are you sure you want to change your decision and approve?',
}

export default function EmailConfirmation() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(true)
  const [isConfirming, setIsConfirming] = useState(false)
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [confirmationText, setConfirmationText] = useState('')
  const refIsManager = useRef<boolean | undefined>(undefined)
  const [isManager, setIsManager] = useState<boolean | undefined>(undefined)
  const [confirmed, setConfirmed] = useState<boolean | undefined>(undefined)
  const refData = useRef({
    token: '',
    value: false,
  })
  const isMobile = useMediaQuery('(max-width: 768px)')
  const [isAnimating, setIsAnimating] = useState(false)

  const handleAnimationTrigger = () => {
    setIsAnimating(true) // Trigger the animation
    // Reset animation after it completes
    setTimeout(() => {
      setIsAnimating(false)
    }, 1000) // Duration should match the animation duration
  }

  const containerBackgroundImage = useMemo(() => {
    if (error) return isMobile ? backgroundImageMobile : backgroundImage
    if (isManager === undefined) return ''
    return isManager
      ? isMobile
        ? backgroundImageMobile
        : backgroundImage
      : isMobile
      ? backgroundImageCandidateMobile
      : backgroundImageCandidate
  }, [error, isManager, isMobile])

  const mainImage = useMemo(() => {
    if (showConfirmation) {
      return questionImage
    }
    if (confirmed === true) {
      return confirmImage
    } else if (confirmed === false) {
      return cancelImage
    }
  }, [confirmed, showConfirmation])

  const confirmEmail = useCallback(async (token: string, value: boolean) => {
    try {
      setIsConfirming(true)
      const result = await API.confirmEmail(token, value)
      if (!result || !result.success) {
        setError(commonError)
        return
      }
      // enrollment confirmation, yes or no
      if (value) {
        setMessage(
          refIsManager.current
            ? ConfirmationText.ManagerConfirmed
            : ConfirmationText.LearnerConfirmed,
        )
        // play animation if the value is "confirmed"
        handleAnimationTrigger()
      } else {
        setMessage(
          refIsManager.current
            ? ConfirmationText.ManagerCancelled
            : ConfirmationText.LearnerCancelled,
        )
      }
      setConfirmed(value)
    } catch (e) {
      setError(commonError)
    } finally {
      setIsConfirming(false)
      setShowConfirmation(false)
    }
  }, [])

  const onClickYes = () => {
    confirmEmail(refData.current.token, refData.current.value)
  }

  const onClickNo = () => {
    setShowConfirmation(false)
  }

  const fetchLearnerStatus = useCallback(
    async (token: string, value: boolean) => {
      try {
        setIsLoading(true)
        const result = await API.getConfirmEmailStatus(token)
        console.log(result)
        refIsManager.current = result.isManager
        setIsManager(result.isManager)
        let isChanged = false
        let currentValue = undefined
        if (result.learnerConfirmationAt) {
          if (result.learnerConfirmation !== value) {
            isChanged = true
          }
          currentValue = result.learnerConfirmation
          setConfirmed(currentValue)
        } else if (result.managerConfirmationAt) {
          if (result.managerConfirmation !== value) {
            isChanged = true
          }
          currentValue = result.managerConfirmation
          setConfirmed(currentValue)
        }

        // show confirmation dialog
        if (isChanged) {
          refData.current.token = token
          refData.current.value = value

          if (value) {
            setMessage(
              refIsManager.current
                ? ConfirmationText.ManagerChangeCancelToConfirm
                : ConfirmationText.LearnerChangeCancelToConfirm,
            )
            setConfirmationText(
              refIsManager.current
                ? ConfirmationText.ManagerChangeCancelToConfirmQuestion
                : ConfirmationText.LearnerChangeCancelToConfirmQuestion,
            )
          } else {
            setMessage(
              refIsManager.current
                ? ConfirmationText.ManagerChangeConfirmToCancel
                : ConfirmationText.LearnerChangeConfirmToCancel,
            )
            setConfirmationText(
              refIsManager.current
                ? ConfirmationText.ManagerChangeConfirmToCancelQuestion
                : ConfirmationText.LearnerChangeConfirmToCancelQuestion,
            )
          }
          setShowConfirmation(true)
        } else {
          if (currentValue !== undefined) {
            if (currentValue) {
              setMessage(
                refIsManager.current
                  ? ConfirmationText.ManagerConfirmedAlready
                  : ConfirmationText.LearnerConfirmedAlready,
              )
              // play animation if the value is "confirmed"
              handleAnimationTrigger()
            } else {
              setMessage(
                refIsManager.current
                  ? ConfirmationText.ManagerCancelledAlready
                  : ConfirmationText.LearnerCancelledAlready,
              )
            }
            setConfirmed(currentValue)
          } else {
            // no previous confirmation, confirm it
            await confirmEmail(token, value)
          }
        }
      } catch (e) {
        setError(commonError)
      } finally {
        setIsLoading(false)
      }
    },
    [confirmEmail],
  )

  const showErrorMessage = useCallback((message: string) => {
    setError(message)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    // find token from query string
    const token = searchParams.get('token')
    const confirm = searchParams.get('confirm')
    if (!token) {
      showErrorMessage('No token found in query string.')
      return
    }
    if (confirm !== '1' && confirm !== '0') {
      showErrorMessage('No confirmation found in query string.')
      return
    }
    const value = confirm === '1'
    fetchLearnerStatus(token, value)
  }, [searchParams, fetchLearnerStatus, showErrorMessage])

  return (
    <>
      <Helmet>
        <title>Confirmation | Mentem by UNSW</title>
      </Helmet>
      <div
        className={styles.container}
        style={{
          backgroundImage: `url(${containerBackgroundImage})`,
          backgroundSize: 'cover', // Ensures the image covers the entire div
          backgroundPosition: 'center', // Centers the image
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          backgroundAttachment: 'fixed', // Keeps the background fixed when scrolling
          imageRendering: 'auto', // Ensures browser renders the image at its best quality
          paddingBottom: `${isMobile ? '30%' : '12%'}`,
        }}
      >
        {isLoading && (
          <div>
            <GenericLoadingIndicator />
          </div>
        )}
        {!isLoading && message && (
          <>
            <img
              src={mainImage}
              alt="Icon"
              className={isAnimating ? styles.triggerAnimation : ''} // Conditionally apply the animation class
            />
            <div
              style={{
                height: '24px',
              }}
            />
            <div className={styles.message}>{message}</div>
            {showConfirmation && (
              <>
                <div className={styles.message}>{confirmationText}</div>
                <div
                  style={{
                    height: `${isMobile ? '24px' : '12px'}`,
                  }}
                />
                <div className={styles.buttons}>
                  <button
                    className={styles.actionButtonSecondary}
                    onClick={onClickNo}
                    disabled={isConfirming}
                  >
                    No
                  </button>
                  <button
                    className={styles.actionButtonPrimary}
                    onClick={onClickYes}
                    disabled={isConfirming}
                  >
                    {isConfirming ? (
                      <GenericLoadingIndicator size="16px" color="#ffffff" />
                    ) : (
                      'Yes'
                    )}
                  </button>
                </div>
              </>
            )}
          </>
        )}
        {!isLoading && !error && (
          <>
            <div
              style={{
                height: `${isMobile ? '24px' : '12px'}`,
              }}
            />
            <div className={styles.message}>
              If you have any questions, please contact{' '}
              <a
                href="mailto:help@mentem.co"
                style={{ textDecoration: 'underline' }}
              >
                help@mentem.co
              </a>
            </div>
          </>
        )}
        {!isLoading && error && (
          <>
            <img src={failSvg} alt="Failed" />
            <div
              style={{
                height: '24px',
              }}
            />
            <div className={styles.error}>{error}</div>
            <div
              style={{
                height: `${isMobile ? '24px' : '12px'}`,
              }}
            />
            <div
              className={styles.message}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <span>Please try clicking the link in your email again,</span>
              <span>
                and contact{' '}
                <a
                  href="mailto:help@mentem.co"
                  style={{ textDecoration: 'underline' }}
                >
                  help@mentem.co
                </a>{' '}
                if you have further issues.
              </span>
            </div>
          </>
        )}
      </div>
    </>
  )
}
