import React, { useRef, useState } from 'react'
import styles from './GenericSessionVideo.module.css'
import { ActionType, trackEvent } from 'utils/AnalyticsTracker'

interface GenericSessionVideoProps {
  title: string
  description: string
  videoThumbnail: string
  videoUrl: string
  background?: string
}

export const GenericSessionVideo: React.FC<GenericSessionVideoProps> = ({
  title,
  description,
  videoThumbnail,
  videoUrl,
  background,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isCoverVisible, setIsCoverVisible] = useState(true)

  const handlePlayVideo = (): void => {
    setIsCoverVisible(false)
    if (videoRef.current) {
      videoRef.current.play()
    }
    trackEvent('videos', ActionType.PLAY, videoUrl)
  }

  return (
    <div
      id="videos"
      className={styles.container}
      style={{
        backgroundImage: `url('${background}')`,
        backgroundColor: 'rgba(0, 0, 0, 1)',
      }}
    >
      <div className={styles.content} style={{ color: 'rgba(255,255,255,1)' }}>
        <div className={styles.part1}>
          <h1
            className={styles.title}
            style={{ color: 'rgba(255, 255, 255, 1)' }}
          >
            {title}
          </h1>
          <p className={styles.description}>
            {description.split('\\n').map((item, key) => {
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              )
            })}
          </p>
        </div>
        <div className={styles.part2}>
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
          >
            {isCoverVisible && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={handlePlayVideo}
              >
                <img
                  style={{ position: 'absolute', width: '8%' }}
                  src="/btn-play.png"
                  alt="play"
                />
                <img
                  src={videoThumbnail}
                  alt={title}
                  style={{
                    width: '100%',
                    height: '100%',
                    aspectRatio: '16/9',
                    borderRadius: '20px',
                  }}
                />
              </div>
            )}
            <video
              ref={videoRef}
              width="100%"
              controls
              // onPlay={handlePlayVideo}
              style={{
                display: isCoverVisible ? 'none' : 'block',
                borderRadius: '20px',
              }}
            >
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  )
}
