import React from 'react'
import styles from './SessionScheduleForRla.module.css'
import { useDataContext } from 'context/DataProvider'

interface Props {
  titleColor: string
  backgroundNumberColor: string
  numberColor: string
  numberSize: string
  closeDateTextColor: string
  closeDateColor: string
  progressBorderColor: string
  progressBarColor: string
  upcomingProgramsColor: string
  upcomingPrograms: string[]
}

export const SessionScheduleForRla: React.FC<Props> = ({
  titleColor,
  backgroundNumberColor,
  numberColor,
  numberSize,
  closeDateTextColor,
  closeDateColor,
  progressBorderColor,
  progressBarColor,
  upcomingProgramsColor,
  upcomingPrograms,
}) => {
  const { program } = useDataContext()

  // calculate progress: open date, current date, close date, 100%
  const calculateProgress = (
    openDate: string | undefined,
    closeDate: string | undefined,
  ): number => {
    if (!openDate || !closeDate) return 0
    const open = new Date(openDate)
    const close = new Date(closeDate)
    if (!open || !close) return 0
    const current = new Date()
    const total = close.getTime() - open.getTime()
    const elapsed = current.getTime() - open.getTime()
    let progress = elapsed / total
    if (progress < 0) progress = 0
    if (progress > 1) progress = 1
    return progress
  }

  const formatDate = (dateString: string | undefined): string => {
    if (!dateString) return ''
    const date = new Date(dateString)
    const day = date.getUTCDate()
    const month = date.toLocaleString('en-US', { month: 'short' })
    const year = date.getUTCFullYear()

    return `${day} ${month} ${year}`
  }

  return (
    <div id="schedule" className={styles.container}>
      <div className={styles.content}>
        <div className={styles.part1}>
          <h1
            className={styles.title}
            style={{ color: titleColor, textAlign: 'left' }}
          >
            Current submissions
          </h1>
          <div
            style={{
              fontSize: numberSize,
              fontWeight: 700,
              color: numberColor,
              background: backgroundNumberColor,
              borderRadius: '20px',
              padding: '10px',
              width: '100%',
            }}
          >
            {program?.submissionsCount || '0' || '‎'}
          </div>
          <div
            style={{
              fontSize: '25px',
              marginTop: '12px',
              padding: '0px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              style={{
                color: closeDateTextColor,
                textAlign: 'left',
              }}
            >
              Applications for next program close on
              {''}
            </span>
            <span
              style={{
                fontWeight: 'normal',
                color: closeDateColor,
                textAlign: 'right',
              }}
            >
              {formatDate(program?.closeDate) || '‎'}
            </span>
          </div>
          <div
            style={{
              marginTop: '18px',
              border: `2px solid ${progressBorderColor}`,
              borderRadius: '20px',
              width: '100%',
              minWidth: '330px',
              height: '36px',
            }}
          >
            <div
              style={{
                minWidth: '28px',
                width: `${
                  calculateProgress(program?.openDate, program?.closeDate) * 100
                }%`,
                height: '100%',
                borderRadius: '20px',
                padding: '0px',
                background: progressBarColor,
              }}
            ></div>
          </div>
        </div>
        {/* divider */}
        <div className={styles.divider} />
        <div className={styles.part2}>
          <div
            style={{
              color: titleColor,
              fontWeight: 700,
              fontSize: '46px',
              marginBottom: '36px',
            }}
          >
            Program dates
          </div>
          <div
            style={{
              fontSize: '25px',
              fontWeight: 700,
              display: 'flex',
              flexDirection: 'column',
              gap: '32px',
            }}
          >
            <p>Upcoming programs:</p>
            <div
              className={styles.list}
              style={{
                fontWeight: 600,
              }}
            >
              <ul>
                {upcomingPrograms.map((date, index) => (
                  <li
                    key={index}
                    style={{
                      color: upcomingProgramsColor,
                    }}
                  >
                    <span></span>
                    {date}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
