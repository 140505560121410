import React from 'react'
import styles from './GenericSessionCTA.module.css'
import { Link } from 'react-router-dom'

interface GenericSessionCTAProps {
  title: string
  subtitle: string
  description: string
  buttonText: string
}

export const GenericSessionCTA: React.FC<GenericSessionCTAProps> = ({
  title,
  subtitle,
  description,
  buttonText,
}) => {
  return (
    <div id="eoi" className={styles.container}>
      <Link to="form">
        <div
          style={{
            background: 'transparent',
            overflow: 'hidden',
            borderRadius: '20px',
          }}
        >
          <div className={styles.frame}>
            <div className={styles.content}>
              <h1 className={styles.title}>{title}</h1>
              <p className={styles.subtitle}>
                <b>{subtitle}</b>
              </p>
              <br></br>
              <div className={styles.description}>
                {description.includes('<b>') ? (
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                ) : (
                  description
                    .split('\n')
                    .map((line, index) => <p key={index}>{line}</p>)
                )}
              </div>
              <button className={styles.cta}>{buttonText}</button>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}
