import { useDataContext } from '../context/DataProvider'
import styles from './GenericFormWelcomeV3.module.css'
import { LoadingIndicator } from 'components/LoadingIndicator'
import {
  Checkbox,
  FormControlLabel,
  Grow,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import {
  dropdownItemStyles,
  dropdownOutlineLabelStyles,
  textFieldOutlineLabelStyles,
} from 'utils/MuiStyles'
import { useRef, useState } from 'react'
import { isEmail } from 'utils/stringUtils'

type PageProps = {
  onContinue: () => void
}

export default function GenericFormWelcomeV3(props: PageProps) {
  const { submission, setSubmission, program } = useDataContext()
  const [rerenderCount, setRerenderCount] = useState(0)
  const shouldValidate = useRef(false)

  const fields = program?.components?.sessionForm?.fields || []
  // console.log('fields:', fields)

  const getErrorMessage = (fieldId: string, label: string) => {
    if (!shouldValidate.current) return ''
    const value = submission?.[fieldId]?.trim() || ''
    if (value === '') return `Please provide your ${label}*`
    if (fieldId === 'email' && !isEmail(value))
      return 'Please provide a valid email address*'
    return ''
  }

  const readyForSubmit = (): boolean => {
    return (
      fields.every((field) => {
        const value = submission?.[field.id]?.trim() || ''
        if (field.required && value === '') return false
        if (field.type === 'email' && !isEmail(value)) return false
        return true
      }) && !!submission?.tncAgreeAt
    )
  }

  const handleClickSubmit = () => {
    shouldValidate.current = true

    if (readyForSubmit()) {
      shouldValidate.current = false
      props.onContinue()
    } else {
      console.log('Invalid profile')
      setRerenderCount(rerenderCount + 1)
    }
  }

  const label = (
    <span>
      I agree to the terms of Mentem's{' '}
      <span style={{ textDecoration: 'underline' }}>
        <a href="https://www.mentem.co/privacy/">Privacy Policy</a>
      </span>
    </span>
  )

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.description} style={{ fontSize: '26px' }}>
          {program?.components?.sessionForm?.title || ''}
        </div>
        <div className={styles.instructions}></div>
        <div className={styles.formcontainer}>
          {fields.map((field) => {
            const errorMessage = getErrorMessage(field.id, field.label)
            const shouldDisplayField =
              !field.dependsOn ||
              submission?.[field.dependsOn] === field.dependsOnValue

            if (field.type === 'select') {
              return (
                <Select
                  key={field.id}
                  style={{
                    width: '100%',
                    border: '1px solid rgba(230, 230, 230, 1)',
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                  }}
                  variant="outlined"
                  sx={dropdownOutlineLabelStyles}
                  displayEmpty
                  renderValue={(selected) => {
                    return errorMessage ? (
                      <div style={{ color: 'rgba(211,47,41,1)' }}>
                        {errorMessage}
                      </div>
                    ) : submission?.[field.id] ? (
                      <div style={{ color: 'rgba(0,0,0,1)' }}>
                        {submission?.[field.id]}
                      </div>
                    ) : (
                      <div style={{ color: 'rgba(0,0,0,0.6)' }}>
                        {field.label}
                      </div>
                    )
                  }}
                  value={submission?.[field.id] || ''}
                  label={field.label}
                  onChange={(e) => {
                    setSubmission({
                      ...submission!,
                      [field.id]: e.target.value,
                    })
                  }}
                >
                  {field.options?.map((option: string) => (
                    <MenuItem
                      value={option}
                      sx={dropdownItemStyles}
                      key={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              )
            } else {
              return (
                shouldDisplayField && (
                  <TextField
                    key={field.id}
                    value={submission?.[field.id] || ''}
                    label={errorMessage || field.label}
                    variant="outlined"
                    sx={{
                      ...textFieldOutlineLabelStyles,
                    }}
                    onChange={(e) => {
                      setSubmission({
                        ...submission!,
                        [field.id]: e.target.value,
                      })
                    }}
                    error={errorMessage !== ''}
                  />
                )
              )
            }
          })}

          <div className={styles.checkboxcontainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={submission?.tncAgreeAt ? true : false}
                  sx={{
                    '&.Mui-checked': {
                      color: 'rgba(186, 97, 255, 1)',
                    },
                  }}
                  onChange={(e) => {
                    setSubmission({
                      ...submission!,
                      tncAgreeAt: e.target.checked ? new Date() : undefined,
                    })
                  }}
                />
              }
              label={label}
              sx={{
                '& .MuiSvgIcon-root': { fontSize: 28 },
              }}
            />
            <Grow
              in={shouldValidate.current && !submission?.tncAgreeAt}
              style={{ transformOrigin: '0 50% 0' }}
            >
              <span className={styles.error}>Required</span>
            </Grow>
          </div>

          {submission?.program ? (
            <button
              onClick={handleClickSubmit}
              className={
                readyForSubmit()
                  ? styles.submitButtonPurple
                  : styles.submitButton
              }
            >
              Submit
            </button>
          ) : (
            <LoadingIndicator />
          )}
        </div>
      </div>
    </div>
  )
}
