import ReactGA from 'react-ga4'

export enum ActionType {
  DOWNLOAD = 'file_download',
  PLAY = 'video_play',
  // Add more action types as needed
}

export const trackEvent = (
  category: string,
  action: ActionType,
  label: string,
  value?: number | undefined,
) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value: value ?? 1,
  })
}
