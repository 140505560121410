import { RadioProps, styled, Radio } from '@mui/material'

const MentemRadioIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 22,
  height: 22,
  boxShadow: 'inset 0 0 0 2px #3C415D, inset 0 -2px 0 #3C415D',
  backgroundColor: '#f5f8fa',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}))

const MentemRadioCheckedIcon = styled(MentemRadioIcon)({
  backgroundColor: '#fff',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 22,
    height: 22,
    backgroundImage: 'radial-gradient(#3C415D,#3C415D 32%,transparent 38%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#fff',
  },
})

export default function MentemRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<MentemRadioCheckedIcon />}
      icon={<MentemRadioIcon />}
      {...props}
    />
  )
}
