import React from 'react'
import styles from './Footer.module.css'

interface Props {
  backgroundColor: string
}

export const Footer: React.FC<Props> = ({ backgroundColor }) => {
  const description = '@ 2024 Mentem by UNSW. All rights reserved.'
  const items = [
    {
      title: 'About Mentem',
      link: 'https://www.mentem.co/',
    },
    {
      title: 'Privacy Policy',
      link: 'https://www.mentem.co/privacy/',
    },
    {
      title: 'Contact us',
      link: 'https://www.mentem.co/contact/',
    },
  ]

  return (
    <footer
      className={styles.container}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <div className={styles.content}>
        <div className={styles.items}>
          {items.map((item, index) => (
            <div key={index} className={styles.item}>
              <a href={item.link} style={{ fontWeight: 700 }}>
                {' '}
                {item.title}
              </a>
            </div>
          ))}
        </div>
        <p className={styles.description}>{description}</p>
      </div>
    </footer>
  )
}
