import { useEffect, useState } from 'react'
import styles from './Topic.module.css'

import DynamicHeightIframe from 'components/DynamicHeightIframe'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { getSlug } from 'utils/stringUtils'
import { API } from 'apis/API'

export default function Topic() {
  const location = useLocation()
  const [title, setTitle] = useState<string | null>(null)
  const [url, setUrl] = useState<string | null>(null)

  const getTopicUrl = async (topicCode: string) => {
    try {
      const data = await API.getTopicUrl(topicCode)
      if (!data) {
        console.error('Invalid topic code')
        return
      }
      if (data.title) {
        setTitle(`${data.title} | Mentem by UNSW`)
      }
      if (data.url) {
        setUrl(data.url)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const topicCode = getSlug(location.pathname)
    if (!topicCode) {
      console.error('Invalid topic code')
      return
    }
    const storedData = localStorage.getItem(`/topics/${topicCode}`)
    if (storedData) {
      const data = JSON.parse(storedData)
      if (data.title) {
        setTitle(`${data.title} | Mentem by UNSW`)
      }
      if (data.url) {
        setUrl(data.url)
      }
    } else {
      getTopicUrl(topicCode)
    }
  }, [location.pathname])

  return (
    <>
      {title && (
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta
            property="og:image"
            content="https://eoi.mentem.co/explore2.jpg"
          />
          <meta
            content="Mentem by UNSW is dedicated to the future of work, building a stronger workforce through reskilling and upskilling at scale."
            property="og:description"
          ></meta>
        </Helmet>
      )}
      <div id="main" className={styles.container}>
        {url && <DynamicHeightIframe className={styles.content} src={url} />}
      </div>
    </>
  )
}
