import React, { useRef } from 'react'
import styles from './ThankYouForRla.module.css'
import { useDataContext } from 'context/DataProvider'
import { useNavigate } from 'react-router-dom'

export default function ThankYouForRla() {
  const { submission } = useDataContext()
  const navigate = useNavigate()

  const shouldValidate = useRef(false)
  const handleClickContinue = () => {
    shouldValidate.current = true
    console.log('submission:', submission)
    navigate('/rla/data-analytics')
  }
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1
          style={{ textAlign: 'center', fontSize: '24px', fontWeight: '700' }}
        >
          Here's what happens next
        </h1>
        <div className={styles.main}>
          <div className={styles.item}>
            <span>
              Thank you for expressing your interest in the program.
              <br />
              <br />
              After submitting this form the Resolution Life Australasia team
              will review all applications and contact shortlisted learners to
              confirm their enrolment in the program.
            </span>
          </div>
        </div>
        <button onClick={handleClickContinue} className={styles.submitbutton}>
          Back to homepage
        </button>
      </div>
    </div>
  )
}
