import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react'
import { API } from 'apis/API'
import {
  Submission,
  defaultSlug,
  getDefaultSubmission,
} from 'apis/models/submission.entity'
import { useNavigate } from 'react-router-dom'
import { Program } from 'apis/models/program.entity'
import { GetProgramDto } from 'apis/models/API.dto'

export interface URLParams {
  [key: string]: string
}

interface DataContextProps {
  step: number
  setStep: (step: number) => void

  preference: Preference
  storePreference: (updatedPreference: Preference) => void

  submission: Submission | undefined
  setSubmission: (submission: Submission | undefined) => void

  program: Program | undefined
  setProgram: (program: Program | undefined) => void

  // programCode: string | undefined
  // clientCode: string | undefined
  setClientCode: (clientCode: string | undefined) => void
  setProgramCode: (programCode: string | undefined) => void

  checkProgram: (
    clientCode: string | undefined,
    programCode: string | undefined,
  ) => void
}

export enum FileMenuMode {
  closed,
  load,
  new,
}

export interface Preference {
  theme: string
  toolTip: number
}

const Context = createContext<DataContextProps | null>(null)

export const useDataContext = (): DataContextProps => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('data context must be inside a provider')
  }
  return context
}

type Props = {
  children: ReactNode
}

export const DataProvider = ({ children }: Props) => {
  // const location = useLocation()
  const [submission, setSubmission] = useState<Submission | undefined>(
    undefined,
  )
  const [step, setStep] = useState(0)
  const [preference, setPreference] = useState<Preference>({
    theme: '',
    toolTip: 1,
  })
  const [program, setProgram] = useState<Program | undefined>(undefined)
  const navigate = useNavigate()
  const [clientCode, setClientCode] = useState<string | undefined>(undefined)
  const [programCode, setProgramCode] = useState<string | undefined>(undefined)

  // Function to set the preference object to localStorage and update state
  const storePreference = (updatedPreference: Preference) => {
    localStorage.setItem('userPreference', JSON.stringify(updatedPreference))
    setPreference(updatedPreference)
  }

  const checkProgram = async (
    _clientCode: string | undefined,
    _programCode: string | undefined,
  ) => {
    //check needs reload
    if (programCode === _programCode && clientCode === _clientCode) {
      return
    }
    //prevent reload
    setClientCode(_clientCode)
    setProgramCode(_programCode)

    const dto: GetProgramDto = {
      clientCode: _clientCode || '',
      programCode: _programCode || '',
    }

    const result = await API.getProgram(dto)
    console.log('getProgram:', result)
    if (result) {
      setProgram(result)
    } else {
      const currentSlug = `${_clientCode}/${_programCode}`
      if (currentSlug !== defaultSlug) {
        navigate(`/`)
      }
    }
  }

  //copy submission and replace program
  useEffect(() => {
    if (program === undefined) {
      return
    }
    const copySubmission = submission ?? getDefaultSubmission(program.client)
    const updatedSubmission = {
      ...copySubmission,
      program: program?._id,
      criteriaJob: program?.criteriaJob,
      returnURL: program?.returnURL,
    }
    console.log('updatedSubmission:', updatedSubmission)
    updatedSubmission && setSubmission({ ...updatedSubmission })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program])

  const providerValue = {
    step,
    setStep,
    preference,
    storePreference,
    submission,
    setSubmission,
    program,
    setProgram,
    setClientCode,
    setProgramCode,
    checkProgram,
  }

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}
