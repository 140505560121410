import React from 'react'
import styles from './SessionFaq.module.css'
import { styled } from '@mui/material/styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useMediaQuery } from '@mui/material'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
  '& .Mui-expanded.MuiAccordionSummary-root': {
    backgroundColor: 'rgba(239, 239, 239, 1)',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  fontWeight: 700,
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  color: 'rgba(59, 49, 125, 1)',
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  whiteSpace: 'pre-line',
}))

interface Props {
  cta?: {
    title: string
    description: string
    email: string
  }
}

export const SessionFaq: React.FC<Props> = ({ cta }) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  const QAs = [
    {
      question: 'What is the purpose of this program?',
      answer:
        'The purpose of the program is enhance your ability to extract value from data, and empower you with the confidence and skills to solve problems using advanced analytics techniques. You will gain practical experience such as applying robust statistical methods, exploring data, and building machine learning models with Python.',
    },
    {
      question: 'How will this program benefit my professional growth?',
      answer:
        'Participation in this program will help you enhance your data analytics skillset, which will enable multiple professional benefits. With this improved skillset, you could elevate the quality of your delivery work. As you continue practicing these skills, you could demonstrate your readiness for promotion or internal mobility.',
    },
    {
      question: 'What do I get for completing the program?',
      answer:
        'A digital credential from Mentem by UNSW and RPL credit towards a UNSW postgraduate qualification in Data Analytics.',
    },
    {
      question: 'How can I participate?',
      answer:
        'First have a development conversation with your manager and obtain their approval to participate and charge to your cost centre.\n\nThen complete the expression of interest form by selecting “Apply now” on this page.\n\nAfter you submit your interest through this portal, you will complete a brief cognitive assessment. From there, Mentem and CBA will work to shortlist candidates for the program.',
    },
    {
      question: 'What should I do before I start?',
      answer:
        'Before starting the program, you should make your manager aware of your participation in this program. This will enable them to better support you and your learning.',
    },
    {
      question: 'What skills and knowledge should I have before signing up?',
      answer:
        'The program is intended for learners with some experience and capability in:\n\n• Collecting and analysing data.\n• Designing and conducting experiments to test hypotheses and validate findings.\n• Communicating analytics findings to stakeholders.\n• Creating visualisations and dashboards to help stakeholders understand data insights.\n• An analytics programming language such as Python.',
    },
    {
      question: `Can I sign up for the program if I'm outside the Data & Analytics Practice?`,
      answer: 'Yes - please discuss with your manager.',
    },
    {
      question: 'How will CBA support me during this program?',
      answer:
        'At CBA we are passionate about your career and development. You will need to discuss with your leader to support your learning development and ensure you have the adequate support to manage your workload. Please ensure you discuss the schedule to allow you to attend the live sessions. In addition you will have access to a group chat where you can reach out to your peers for support and guidance. You can also reach out the D&A Practice if you require any additional support.',
    },
    {
      question:
        'What are the exact start and end dates for each program? When will live sessions run?',
      answer:
        'We are currently planning the detailed dates and schedule for each course. These will be shared with shortlisted candidates as part of the sign-up process in the weeks before you commit to starting the course.',
    },
    {
      question: 'Is attendance mandatory?',
      answer:
        'CBA requires learners to attend 75% of the live sessions. If you are unable to attend a session, you must email help@mentem.co in advance to note your planned absence.',
    },
    {
      question: 'What happens if I have planned leave for part of the program?',
      answer:
        'If you have more than 5 days of leave planned for a program, we recommend choosing a different cohort later in the year. Given the pace of the program, missing an extended period will impact your ability to keep up with the material. ',
    },

    {
      question: 'What about school holidays?',
      answer:
        'We will include a two-week break for the school holidays from Monday 30 September to Friday 11 October 2024. This will also allow all learners some time to reflect and recharge between courses in the program.',
    },
  ]

  return (
    <div id="faqs" className={styles.container}>
      <div className={styles.title}>FAQs</div>
      <div
        className={styles.content}
        style={{
          maxWidth: '800px',
        }}
      >
        {QAs.map((qa, index) => (
          <Accordion key={index}>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              {index + 1}. {qa.question}
            </AccordionSummary>
            <AccordionDetails>{qa.answer}</AccordionDetails>
          </Accordion>
        ))}

        <div
          style={{
            marginTop: '60px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <h1 style={{ fontSize: isMobile ? '30px' : '48px' }}>
            {cta?.title || 'Have more questions?'}
          </h1>
          <p className={styles.description}>
            {cta?.description ||
              'If you have any questions about this program please reach out to'}
            <br />
            <a href={`mailto:${cta?.email || 'hello@mentem.co'}`}>
              <span style={{ color: 'rgba(186, 97, 255, 1)' }}>
                {cta?.email || 'hello@mentem.co'}
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
