import React from 'react'
import styles from './GenericProgram.module.css'
import { SessionProgram } from 'apis/models/program.entity'
import { GenericProgramDetail } from './GenericProgramDetail'
import { GenericProgramAttachment } from './GenericProgramAttachment'
import { GenericProgramTopic } from './GenericProgramTopic'
import GenericCourseSummary from './GenericCourseSummary'

interface Props {
  data: SessionProgram
}

export const GenericProgram: React.FC<Props> = ({ data }) => {
  return (
    <div id={data.id} className={styles.container}>
      {data.detail && (
        <GenericProgramDetail
          mediaOnLeft={data.detail.mediaOnLeft}
          title={data.detail.name}
          description={data.detail.description}
          imageUrl={data.detail.imageUrl}
          videoUrl={data.detail.videoUrl}
        />
      )}
      {data.content && <GenericCourseSummary data={data.content} />}
      {data.attachment && (
        <GenericProgramAttachment
          title={data.attachment.title}
          description={data.attachment.description}
          imageUrl={data.attachment.imageUrl}
          files={data.attachment.files}
        />
      )}
      {data.topic && (
        <GenericProgramTopic
          title={data.topic.title}
          items={data.topic.items}
        />
      )}
    </div>
  )
}
