import { DataProvider } from './context/DataProvider'
import { AxiosClient } from './apis/utils/AxiosClient'
import { UIProvider } from './context/UIProvider'
import { Preloader } from './context/Preloader'
import { Routes, Route, BrowserRouter, useLocation } from 'react-router-dom'
import Form from './pages/Form'
import { useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import Home from 'pages/Home'
import Topic from 'pages/Topic'
import * as Sentry from '@sentry/react'
import ReactGA from 'react-ga4'
import GenericHome from 'pages/GenericHome'
import GenericForm from 'pages/GenericForm'
import HomeForRla from 'pages/HomeForRla'
import FormForRla from 'pages/FormForRla'
import Edit from 'pages/Edit'
import EditForRla from 'pages/EditForRla'
import GenericEdit from 'pages/GenericEdit'
import React from 'react'
import ManagerConsent from 'pages/ManagerConsent'
import EmailConfirmation from 'pages/EmailConfirmation'
// import { isDevelopment, isLocal } from 'utils/EnvUtils'
// import DynamicContext from 'components/DynamicContext'
import HomeForNetm from 'pages/HomeForNetm'
import FormForNetm from 'pages/FormForNetm'
import EditForNetm from 'pages/EditForNetm'
import { ToastContainer } from 'react-toastify'
import {
  getClideCodeFromDomain,
  isCustomDomain,
  isDemoDomain,
} from 'utils/EnvUtils'

const RouteChangeTracker = () => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: location.pathname,
    })
  }, [location])

  return null
}

const RedirectToExternal: React.FC<{ to: string }> = ({ to }) => {
  useEffect(() => {
    // Perform the redirection and replace the current entry in the history stack
    window.location.replace(to)
  }, [to])

  // Render nothing, as we're only handling redirection
  return null
}

function App() {
  const redirectUrl =
    process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'local'
      ? 'https://dev.mentem.co'
      : 'https://www.mentem.co'

  useEffect(() => {
    const env = process.env.REACT_APP_ENV
    console.log('env:', env)
    if (env === 'dev' || env === 'prd') {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        tracesSampleRate: 0.1,
        environment: env,
      })

      //GA4
      ReactGA.initialize(process.env.REACT_APP_GA4_ID || '')
    }
  }, [])

  let clientCode = ''
  let programCode = ''

  if (isCustomDomain()) {
    clientCode = getClideCodeFromDomain()
    programCode = window.location.pathname.split('/')[1] || ''
  } else {
    clientCode = window.location.pathname.split('/')[1] || ''
    programCode = window.location.pathname.split('/')[2] || ''
  }
  // console.log('clientCode:', clientCode)
  // console.log('programCode:', programCode)

  return (
    <BrowserRouter>
      <RouteChangeTracker />
      <AxiosClient>
        <DataProvider>
          <UIProvider>
            <Preloader>
              <div className="App">
                <ToastContainer style={{ color: 'red' }} />
                <Routes>
                  {isDemoDomain && (
                    <>
                      <Route
                        path="/"
                        element={
                          <GenericHome clientCode="mentem" programCode="demo" />
                        }
                      />
                      <Route
                        path="/form"
                        element={
                          <GenericForm clientCode="mentem" programCode="demo" />
                        }
                      />
                      <Route
                        path="/form/:subpage"
                        element={
                          <GenericForm clientCode="mentem" programCode="demo" />
                        }
                      />
                    </>
                  )}
                  {isCustomDomain() && (
                    <>
                      <Route
                        path="/:programCode/form"
                        element={
                          <GenericForm
                            clientCode={clientCode}
                            programCode={programCode}
                          />
                        }
                      />
                      <Route
                        path="/:programCode/form/:subpage"
                        element={
                          <GenericForm
                            clientCode={clientCode}
                            programCode={programCode}
                          />
                        }
                      />
                      <Route
                        path="/:programCode"
                        element={
                          <GenericHome
                            clientCode={clientCode}
                            programCode={programCode}
                          />
                        }
                      />
                    </>
                  )}

                  <Route
                    path="/email-confirmation"
                    element={<EmailConfirmation />}
                  />

                  <Route path="/manager-consent" element={<ManagerConsent />} />

                  <Route path={`/topics/:topicName`} element={<Topic />} />

                  <Route
                    path={`/mentem/:programCode`}
                    element={
                      <GenericHome
                        clientCode="mentem"
                        programCode={programCode}
                      />
                    }
                  />

                  <Route
                    path={`/mentem/:programCode/form`}
                    element={
                      <GenericForm
                        clientCode="mentem"
                        programCode={programCode}
                      />
                    }
                  />

                  <Route
                    path={`/mentem/:programCode/form/:subpage`}
                    element={
                      <GenericForm
                        clientCode="mentem"
                        programCode={programCode}
                      />
                    }
                  />

                  <Route
                    path={`/netm/:programCode`}
                    element={<HomeForNetm />}
                  />

                  <Route
                    path={`/netm/:programCode/form`}
                    element={<FormForNetm />}
                  />

                  <Route
                    path={`/netm/:programCode/form/:subpage`}
                    element={<FormForNetm />}
                  />

                  <Route path={`/rla/:programCode`} element={<HomeForRla />} />

                  <Route
                    path={`/rla/:programCode/form`}
                    element={<FormForRla />}
                  />

                  <Route
                    path={`/rla/:programCode/form/:subpage`}
                    element={<FormForRla />}
                  />

                  <Route path={`/cba/:programCode`} element={<Home />} />

                  <Route
                    path={`/:clientCode/:programCode`}
                    element={
                      <GenericHome
                        clientCode={clientCode}
                        programCode={programCode}
                      />
                    }
                  />

                  <Route
                    path="/rla/:programCode/edit"
                    element={<EditForRla />}
                  />

                  <Route
                    path="/netm/:programCode/edit"
                    element={<EditForNetm />}
                  />

                  <Route
                    path="/mentem/:programCode/edit"
                    element={<GenericEdit />}
                  />

                  <Route
                    path="/suncorp/:programCode/edit"
                    element={<GenericEdit />}
                  />

                  <Route
                    path="/:clientCode/:programCode/edit"
                    element={<Edit />}
                  />

                  <Route path={`/cba/:programCode/form`} element={<Form />} />

                  <Route
                    path={`/:clientCode/:programCode/form`}
                    element={
                      <GenericForm
                        clientCode={clientCode}
                        programCode={programCode}
                      />
                    }
                  />

                  <Route
                    path={`/cba/:programCode/form/:subpage`}
                    element={<Form />}
                  />

                  <Route
                    path={`/:clientCode/:programCode/form/:subpage`}
                    element={
                      <GenericForm
                        clientCode={clientCode}
                        programCode={programCode}
                      />
                    }
                  />

                  <Route
                    path="/*"
                    element={<RedirectToExternal to={redirectUrl} />}
                  />

                  {/* {(isLocal || isDevelopment) && (
                    <Route
                      path={`/:clientCode/:programCode/context`}
                      element={<DynamicContext onContinue={() => {}} />}
                    />
                  )} */}
                </Routes>
              </div>
            </Preloader>
          </UIProvider>
        </DataProvider>
      </AxiosClient>
    </BrowserRouter>
  )
}

export default App
