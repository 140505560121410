import { QuestionSpace } from './question.entity'

export interface Question {
  id: string
  question: string | undefined
  placeholder?: string | undefined
  answer: string | undefined
}
export interface Submission {
  _id?: string
  program: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  email?: string
  managerFirstName?: string
  managerEmail?: string
  businessUnit?: string
  roleType?: string
  role?: string
  location?: string
  linkedIn?: string

  tncAgreeAt?: Date

  questions?: Question[]
  questionSpaces?: QuestionSpace[]
  dates?: string[]
  criteriaJob?: string
  returnURL?: string

  tnc2AgreeAt?: Date
  createdAt?: Date
  updatedAt?: Date

  // rla
  rlaChapterLeadDiscussionConfirmationAt?: Date
  rlaTimeAndLeaveCommitmentConfirmationAt?: Date
  otherLocation?: string

  // netm
  otherInformation?: string

  // generic fields
  jobTitle?: string
  organisation?: string
  phoneNumber?: string
  numberOfLearners?: string
  preferredCourseDate?: string
  preferredPrograms?: string[]
  otherProgram?: string

  // custom fields
  [key: string]: any
}

export const getDefaultSubmission = (client: string): Submission => {
  return {
    program: undefined,
    firstName: process.env.REACT_APP_DEBUG_FIRST_NAME || '',
    lastName: process.env.REACT_APP_DEBUG_LAST_NAME || '',
    email: process.env.REACT_APP_DEBUG_EMAIL || '',
    managerFirstName: process.env.REACT_APP_DEBUG_MANAGER_FIRST_NAME || '',
    managerEmail: process.env.REACT_APP_DEBUG_MANAGER_EMAIL || '',
    questions: undefined,
    tncAgreeAt: process.env.REACT_APP_DEBUG_TNC_AGREED ? new Date() : undefined,
    rlaChapterLeadDiscussionConfirmationAt: undefined,
    rlaTimeAndLeaveCommitmentConfirmationAt: undefined,
    // generic fields
    jobTitle: process.env.REACT_APP_DEBUG_JOB_TITLE || '',
    organisation: process.env.REACT_APP_DEBUG_ORGANISATION || '',
    phoneNumber: '',
    numberOfLearners: process.env.REACT_APP_DEBUG_LEARNER_NUMBER || '',
    preferredCourseDate: process.env.REACT_APP_DEBUG_COURSE_DATE || '',
  }
}

export const defaultSlug: string = '' //'mentem/data-analytics-essentials'
