import { createContext, useContext, useState, ReactNode } from 'react'

export enum PopupContentType {
  None = '',
  Export = 'export',
  Library = 'library',
}

interface UIContextProps {
  isPageLoading: boolean
  setIsPageLoading: (isLoading: boolean) => void
  isModalOpen: boolean
  setIsModalOpen: (isOpen: boolean) => void
  wishName: string
  setWishName: (item: string) => void
  popupContentType: PopupContentType
  setPopupContentType: (popupContentType: PopupContentType) => void
}

const UIContext = createContext<UIContextProps | null>(null)

export const useUIContext = (): UIContextProps => {
  const uiContext = useContext(UIContext)
  if (!uiContext) {
    throw new Error('UI context must be inside a provider')
  }
  return uiContext
}

type Props = {
  children: ReactNode
}
export const UIProvider = ({ children }: Props) => {
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [wishName, setWishName] = useState<string>('')
  const [popupContentType, setPopupContentType] = useState<PopupContentType>(
    PopupContentType.None,
  )

  const providerValue = {
    isPageLoading,
    setIsPageLoading,
    isModalOpen,
    setIsModalOpen,
    wishName,
    setWishName,
    popupContentType,
    setPopupContentType,
  }

  return (
    <UIContext.Provider value={providerValue}>{children}</UIContext.Provider>
  )
}
