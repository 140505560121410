export type ProcessMessageCallback = (
  message: string,
  accumulatedMessage: string,
  isFinished: boolean,
  params: any,
) => void

export const fetchStream = async (
  url: string,
  dto: any,
  processMessageCallback: ProcessMessageCallback,
  params: any,
  fetchTimeout: number = 30000, // Default fetch timeout of 5 seconds
  streamTimeout: number = 30000, // Default stream timeout of 10 seconds
): Promise<any> => {
  let streamTimeoutId: any = null
  let isStreamClosed = false // Flag to indicate if the stream is closed

  const clearTimeouts = () => {
    if (streamTimeoutId) {
      clearTimeout(streamTimeoutId)
      streamTimeoutId = null
    }
  }

  try {
    if (!navigator.onLine) {
      throw new Error('No internet connection')
    }

    const controller = new AbortController()

    const fetchWithTimeout = (
      fetchPromise: Promise<Response>,
      timeout: number,
    ) => {
      return Promise.race([
        fetchPromise,
        new Promise((_, reject) =>
          setTimeout(() => {
            controller.abort()
            reject(new Error('Request timed out'))
          }, timeout),
        ),
      ])
    }

    const response = await fetchWithTimeout(
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dto),
        signal: controller.signal,
      }),
      fetchTimeout,
    )

    if (!(response instanceof Response)) {
      throw new Error(`Unexpected error or timeout occurred`)
    }

    if (!response.ok) {
      throw new Error(
        `HTTP error! Status: ${response.status}, StatusText: ${response.statusText}`,
      )
    }

    if (!response.body) {
      throw new Error('Response body is null')
    }

    const reader = response.body.getReader()
    const textDecoder = new TextDecoder()
    let partialChunk = ''
    let accumulatedMessage = ''

    const processJsonObject = (jsonString: string) => {
      if (jsonString.trim() !== '') {
        try {
          const jsonObject = JSON.parse(jsonString)
          const message = jsonObject.data.message
          accumulatedMessage += message
          processMessageCallback(message, accumulatedMessage, false, params)
        } catch (error) {
          console.error('Error parsing JSON:', error)
        }
      }
    }

    const resetStreamTimeout = () => {
      clearTimeouts() // Clear previous timeout before setting a new one
      streamTimeoutId = setTimeout(() => {
        if (!isStreamClosed) {
          console.error('Stream timed out due to inactivity')
          controller.abort() // Abort the stream if it times out
          throw new Error('Stream timed out due to inactivity')
        }
      }, streamTimeout)
    }

    resetStreamTimeout() // Set the timeout for the first chunk

    while (true) {
      const { done, value } = await reader.read()

      if (done) {
        break
      }

      resetStreamTimeout() // Reset the timeout with each new chunk

      const decodedChunk = textDecoder.decode(value)
      const combinedData = partialChunk + decodedChunk
      const jsonObjects = combinedData.split('\n')

      jsonObjects.forEach(processJsonObject)
      partialChunk = jsonObjects[jsonObjects.length - 1]
    }

    clearTimeouts() // Clear the timeout when done
    isStreamClosed = true // Mark stream as closed
    processMessageCallback('', accumulatedMessage, true, params)
    console.log('Stream closed')
  } catch (error: any) {
    clearTimeouts() // Ensure timeout is cleared in case of any error
    if (error.message === 'No internet connection') {
      console.error('Network error: No internet connection')
    } else if (error.message === 'Request timed out') {
      console.error('Network error: Request timed out')
    } else if (error.message === 'Stream timed out due to inactivity') {
      console.error('Stream error: Stream timed out')
    } else if (error instanceof TypeError) {
      console.error('Network error or resource unreachable:', error.message)
    } else {
      console.error('Error streaming:', error.message)
    }
    return { success: false, message: 'Please check internet connection' }
  }
}
