export const isProduction: boolean = process.env.REACT_APP_ENV === 'prd'
export const isDevelopment: boolean = process.env.REACT_APP_ENV === 'dev'
export const isLocal: boolean = process.env.REACT_APP_ENV === 'local'

export const isDemoDomain = window.location.hostname.includes('demo.mentem.co')
//  || window.location.hostname.includes('localhost')

export const isSuncorpDomain =
  window.location.hostname.includes('suncorp.mentem.co')

export function isCustomDomain() {
  return isSuncorpDomain
}

export function getClideCodeFromDomain() {
  if (isSuncorpDomain) {
    return 'suncorp'
  }
  return ''
}
