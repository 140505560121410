import { useDataContext } from '../context/DataProvider'
import styles from './HomeForNetm.module.css'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import '@egjs/react-flicking/dist/flicking.css'
import { GenericSessionCTA } from 'components/GenericSessionCTA'
import GenericAppBar from 'components/GenericAppBar'
import { GenericSessionVideo } from 'components/GenericSessionVideo'
import { GenericSessionFaq } from 'components/GenericSessionFaq'
import { Footer } from 'components/Footer'
import { Helmet } from 'react-helmet'
import { GenericLoadingIndicator } from 'components/GenericLoadingIndicator'
import { SessionHeroForNetm } from 'components/SessionHeroForNetm'
import { SessionScheduleForNetm } from 'components/SessionScheduleForNetm'
import { SessionScheduleTableForNetm } from 'components/SessionScheduleTableForNetm'
import { SessionBlocksForNetm } from 'components/SessionBlocksForNetm'
import { SessionBoxesForNetm } from 'components/SessionBoxesForNetm'
import GenericCourseSummary from 'components/GenericCourseSummary'

export default function GenericHome() {
  const { checkProgram, program } = useDataContext()
  const { clientCode, programCode } = useParams()

  useEffect(() => {
    const tmpClientCode =
      clientCode || window.location.pathname.split('/')[1] || 'netm'
    if (!tmpClientCode || !programCode) return
    console.log('Home:', tmpClientCode, programCode)
    checkProgram(tmpClientCode, programCode)
  }, [checkProgram, clientCode, programCode, program])

  return (
    <div id="main" className={styles.container}>
      {program && program?.name && (
        <Helmet>
          <title>{program?.name}</title>
        </Helmet>
      )}
      <GenericAppBar data={program?.components?.sessionAppBar} />
      {program && (
        <>
          <div id="content" style={{ position: 'relative' }}>
            {program?.components?.background && (
              <img
                src={program.components.background}
                alt="top"
                style={{
                  position: 'absolute',
                  width: '100%',
                  top: '0%',
                  zIndex: -1,
                }}
              />
            )}

            <SessionHeroForNetm
              title={program?.name || ''}
              subtitle={program?.components?.sessionHero.subtitle || ''}
              background={program?.components?.sessionHero.background || ''}
              color={program?.components?.sessionHero.color || ''}
            />

            <GenericSessionCTA
              title={program?.components?.sessionCTA.title || ''}
              subtitle={program?.components?.sessionCTA.subtitle || ''}
              description={program?.components?.sessionCTA.description || ''}
              buttonText={program?.components?.sessionCTA.buttonText || ''}
            />

            {program?.components?.sessionCourseSummary && (
              <>
                <GenericCourseSummary
                  data={program.components.sessionCourseSummary}
                />
                <div style={{ height: '24px' }} />
              </>
            )}

            <GenericSessionVideo
              title={program?.components?.sessionVideo.title || ''}
              description={program?.components?.sessionVideo.description || ''}
              videoThumbnail={
                program?.components?.sessionVideo.videoThumbnail || ''
              }
              videoUrl={program?.components?.sessionVideo.videoUrl || ''}
            />

            <SessionScheduleForNetm
              titleColor="rgba(0,0,0,1)"
              backgroundNumberColor="#000"
              numberColor="rgba(255,255,255,1)"
              numberSize="36px"
              closeDateTextColor="#000"
              closeDateColor="#BA61FF"
              progressBorderColor="#282978"
              progressBarColor="#BA61FF"
              title={program?.components?.sessionSchedule.title || ''}
              description={
                program?.components?.sessionSchedule.description || ''
              }
            />

            <SessionScheduleTableForNetm
              backgroundColor="#000"
              clientLogo={program?.components?.sessionScheduleTable.clientLogo}
              head={program?.components?.sessionScheduleTable.head || {}}
              rows={program?.components?.sessionScheduleTable.rows || []}
            />

            <SessionBlocksForNetm
              title={program?.components?.sessionBrochure?.title || ''}
              description={
                program?.components?.sessionBrochure?.description || ''
              }
              syllabusThumbnails={
                program?.components?.sessionBrochure?.syllabusThumbnails || []
              }
              syllabusFiles={
                program?.components?.sessionBrochure?.syllabusFiles || []
              }
              topics={program?.components?.sessionDemoTopics.items || []}
            />
          </div>

          <SessionBoxesForNetm />

          <img
            src="https://content.mentem.co/eoi/_share/bottom.jpg"
            alt="bottom"
            style={{
              position: 'absolute',
              width: '100%',
              bottom: '210px',
              zIndex: -1,
            }}
          />

          <GenericSessionFaq
            items={program?.components?.sessionFaq.items || []}
            cta={program?.components?.sessionFaq.cta}
          />
          <Footer backgroundColor="#000" />
        </>
      )}
      {!program && (
        <div className={styles.center}>
          <GenericLoadingIndicator />
        </div>
      )}
    </div>
  )
}
