import React from 'react'
import styles from './SessionScheduleTable.module.css'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material'
import Collapse from '@mui/material/Collapse'

interface Props {
  backgroundColor: string
  clientLogo: string
  head: any
  rows: any[]
}

export const SessionScheduleTable: React.FC<Props> = ({
  backgroundColor,
  clientLogo,
  head,
  rows,
}) => {
  const [showDetails, setShowDetails] = React.useState(true)
  const isMobile = useMediaQuery('(max-width: 767px)')

  return (
    <div id="program-structure" className={styles.container}>
      <div style={{ fontSize: isMobile ? '30px' : '60px', fontWeight: 'bold' }}>
        Program structure
      </div>
      <div
        style={{
          marginTop: '24px',
          fontSize: '25px',
          fontWeight: 'bold',
          color: 'rgba(40,41,120,1)',
        }}
      >
        Course schedule
      </div>
      <div
        style={{
          marginTop: '40px',
          width: '100%',
          paddingLeft: isMobile ? '0px' : '60px',
          paddingRight: isMobile ? '0px' : '60px',
        }}
      >
        <div
          style={{
            backgroundColor: backgroundColor,
            color: 'rgba(255,255,255,1)',
            width: '100%',
            paddingTop: '20px',
            paddingLeft: isMobile ? '8px' : '48px',
            paddingRight: isMobile ? '8px' : '48px',
            borderRadius: '20px',
          }}
        >
          {/* logos   */}
          <div
            className={styles.logos}
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
            }}
          >
            <div style={{ flex: 1 }}>
              <img src={clientLogo} alt="cba" style={{ width: '220px' }} />
            </div>
            <div style={{ flex: 1 }}>
              <img
                src="/mentem.png"
                alt="mentem"
                style={{ width: '270px', padding: '20px' }}
              />
            </div>
            <div style={{ flex: 1 }}> </div>
          </div>
          <div>
            <h1 style={{ paddingBottom: '20px' }}>
              Sample schedule for course 1
            </h1>
            <Collapse in={showDetails}>
              <TableContainer className={styles.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={styles.dark}>{head.col1}</TableCell>
                      <TableCell align="right">{head.col2}</TableCell>
                      <TableCell className={styles.dark} align="right">
                        {head.col3}
                      </TableCell>
                      <TableCell align="right">{head.col4}</TableCell>
                      <TableCell className={styles.dark} align="right">
                        {head.col5}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow key={row.col1}>
                        <TableCell component="th" scope="row">
                          {row.col1}
                        </TableCell>
                        <TableCell align="right" className={styles.dark}>
                          {row.col2}
                        </TableCell>
                        <TableCell align="right">{row.col3}</TableCell>
                        <TableCell className={styles.dark} align="right">
                          {row.col4}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            color:
                              index === rows.length - 1
                                ? 'rgba(186, 97, 255,1)'
                                : 'rgba(0,0,0,0.87)',
                          }}
                        >
                          {row.col5}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </div>
          <div
            onClick={() => setShowDetails(!showDetails)}
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              padding: '24px',
              cursor: 'pointer',
            }}
          >
            {showDetails ? 'Hide details —' : 'Show details ＋'}
          </div>
        </div>
      </div>
    </div>
  )
}
