import React, { useRef, useState } from 'react'
import styles from './GenericProgramDetail.module.css'
import { trackEvent, ActionType } from 'utils/AnalyticsTracker'

interface Props {
  mediaOnLeft: boolean
  title: string
  description: string
  imageUrl: string
  videoUrl?: string
  background?: string
}

export const GenericProgramDetail: React.FC<Props> = ({
  mediaOnLeft,
  title,
  description,
  imageUrl,
  videoUrl,
  background,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isCoverVisible, setIsCoverVisible] = useState(true)

  const handlePlayVideo = (): void => {
    if (!videoUrl) return
    setIsCoverVisible(false)
    if (videoRef.current) {
      videoRef.current.play()
    }
    if (videoUrl) {
      trackEvent('videos', ActionType.PLAY, videoUrl)
    }
  }

  const renderText = () => {
    return (
      <div className={styles.partText}>
        <h1
          className={styles.title}
          style={{ color: 'rgba(255, 255, 255, 1)' }}
        >
          {title}
        </h1>
        <p className={styles.description}>
          {description.split('\\n').map((item, key) => {
            return (
              <span key={key}>
                {item}
                <br />
              </span>
            )
          })}
        </p>
      </div>
    )
  }

  const renderVideo = () => {
    return (
      <div className={styles.partMedia}>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            cursor: `{${videoUrl ? 'pointer' : 'default'}}`,
          }}
        >
          {isCoverVisible && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={handlePlayVideo}
            >
              {videoUrl && (
                <img
                  style={{ position: 'absolute', width: '8%' }}
                  src="/btn-play.png"
                  alt="play"
                />
              )}
              <img
                src={imageUrl}
                alt={title}
                style={{
                  width: '100%',
                  height: '100%',
                  aspectRatio: '16/9',
                  borderRadius: '20px',
                }}
              />
            </div>
          )}
          {videoUrl && (
            <video
              ref={videoRef}
              width="100%"
              controls
              style={{
                display: isCoverVisible ? 'none' : 'block',
                borderRadius: '20px',
              }}
            >
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      </div>
    )
  }

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url('${background}')`,
        backgroundColor: 'rgba(0, 0, 0, 1)',
      }}
    >
      <div className={styles.content} style={{ color: 'rgba(255,255,255,1)' }}>
        {mediaOnLeft ? renderVideo() : renderText()}
        {mediaOnLeft ? renderText() : renderVideo()}
      </div>
    </div>
  )
}
