import React, { useRef, useState } from 'react'
import styles from './SessionDuplex.module.css'
import { ActionType, trackEvent } from 'utils/AnalyticsTracker'

interface Props {
  id: string
  backgroundColor: string
  title: string
  description: string
  titleColor: string
  descriptionColor: string
  image: string
  videoUrl: string
}

export const SessionDuplex: React.FC<Props> = ({
  id,
  backgroundColor,
  title,
  description,
  titleColor,
  descriptionColor,
  image,
  videoUrl,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isCoverVisible, setIsCoverVisible] = useState(true)

  const handlePlayVideo = (): void => {
    setIsCoverVisible(false)
    if (videoRef.current) {
      videoRef.current.play()
    }
    trackEvent('testimonials', ActionType.PLAY, videoUrl)
  }

  return (
    <div
      id={id}
      className={styles.container}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <div className={styles.content}>
        <div className={styles.part1}>
          <h1 className={styles.title} style={{ color: titleColor }}>
            {title}
          </h1>
          <p className={styles.description} style={{ color: descriptionColor }}>
            {description.split('\n').map((item, index) => (
              <span key={index}>
                {item}
                <br />
              </span>
            ))}
          </p>
        </div>
        <div className={styles.part2}>
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
          >
            {isCoverVisible && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={handlePlayVideo}
              >
                <img
                  style={{ position: 'absolute', width: '8%' }}
                  src="/btn-play.png"
                  alt="play"
                />
                <img
                  src={image}
                  alt={title}
                  style={{
                    width: '100%',
                    height: '100%',
                    aspectRatio: '16/9',
                    borderRadius: '20px',
                  }}
                />
              </div>
            )}
            <video
              ref={videoRef}
              width="100%"
              controls
              style={{
                display: isCoverVisible ? 'none' : 'block',
                borderRadius: '20px',
              }}
            >
              {videoUrl && <source src={videoUrl} type="video/mp4" />}
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  )
}
