import { useDataContext } from '../context/DataProvider'
import styles from './WelcomeForNetm.module.css'
import { LoadingIndicator } from 'components/LoadingIndicator'
import { FormControlLabel, Grow, RadioGroup, TextField } from '@mui/material'
import { textFieldOutlineLabelStyles } from 'utils/MuiStyles'
import { useRef, useState } from 'react'
import { isEmail } from 'utils/stringUtils'
import MentemRadio from 'mui/MentemRadio'
import MentemCheckbox from 'mui/MentemCheckbox'

type PageProps = {
  onContinue: () => void
}

export default function WelcomeForNetm(props: PageProps) {
  const { submission, setSubmission, program } = useDataContext()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedEnrollmentType, setselectedEnrollmentType] = useState(
    submission?.enrollmentType,
  )
  const [rerenderCount, setRerenderCount] = useState(0)
  const shouldValidate = useRef(false)

  const getFirstNameErrorMessage = () => {
    if (shouldValidate.current && submission?.firstName?.trim() === '') {
      return 'Please provide your name*'
    } else return ''
  }

  const getOrganisationErrorMessage = () => {
    if (shouldValidate.current && submission?.organisation?.trim() === '') {
      return 'Please provide your organisation*'
    } else return ''
  }

  const getEmailErrorMessage = () => {
    if (!shouldValidate.current) {
      return ''
    }
    const email = submission?.email || ''
    if (!isEmail(email)) {
      return 'Please provide your email address*'
    }
    return ''
  }

  const getTncError = () => {
    if (shouldValidate.current && !submission?.tncAgreeAt) {
      return true
    } else return false
  }

  const getEnrollmentTypeErrorMessage = () => {
    if (shouldValidate.current && !submission?.enrollmentType) {
      return true
    } else return false
  }

  const label = (
    <span>
      I agree to the terms of Mentem's{' '}
      <span style={{ textDecoration: 'underline' }}>
        <a href="https://www.mentem.co/privacy/">Privacy Policy</a>
      </span>
    </span>
  )

  const readyForSubmit = (): boolean => {
    if (submission?.firstName?.trim() === '') {
      return false
    }
    if (submission?.organisation?.trim() === '') {
      return false
    }
    if (!isEmail(submission?.email || '')) {
      return false
    }
    if (!submission?.enrollmentType) {
      return false
    }
    if (!submission?.tncAgreeAt) {
      return false
    }
    return true
  }

  const handleClickSubmit = () => {
    shouldValidate.current = true

    if (readyForSubmit()) {
      shouldValidate.current = false
      props.onContinue()
    } else {
      console.log('Invalid profile')
      setRerenderCount(rerenderCount + 1)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.description} style={{ fontSize: '26px' }}>
          {program?.components?.sessionForm?.title || ''}
        </div>
        <div className={styles.instructions}></div>
        <div className={styles.formcontainer}>
          <TextField
            value={submission?.firstName || ''}
            label={getFirstNameErrorMessage() || 'Your name'}
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            onChange={(e) => {
              setSubmission({ ...submission!, firstName: e.target.value })
            }}
            error={getFirstNameErrorMessage() !== ''}
          />

          <TextField
            value={submission?.email || ''}
            label={getEmailErrorMessage() || 'Your email'}
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            error={getEmailErrorMessage() !== ''}
            onChange={(e) => {
              setSubmission({ ...submission!, email: e.target.value })
            }}
          />

          <TextField
            value={submission?.organisation || ''}
            label={getOrganisationErrorMessage() || 'Your organisation'}
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            onChange={(e) => {
              setSubmission({ ...submission!, organisation: e.target.value })
            }}
            error={getOrganisationErrorMessage() !== ''}
          />

          <p className={styles.question}>
            <span>Is this course for you or your team?</span>
            {getEnrollmentTypeErrorMessage() && (
              <span className={styles.errorText}>Required</span>
            )}
          </p>

          <div className={styles.box}>
            <RadioGroup
              aria-labelledby="is-this-course-for-you-or-your-team"
              defaultValue={selectedEnrollmentType}
              name="enrollmentType-radio-buttons-group"
              onChange={(e) => {
                setSubmission({
                  ...submission!,
                  enrollmentType: e.target.value,
                })
              }}
            >
              <FormControlLabel
                value="individual"
                control={<MentemRadio />}
                label="I want to enrol as an individual"
              />
              <FormControlLabel
                value="group"
                control={<MentemRadio />}
                label="I want to enrol my colleagues or team members"
              />
            </RadioGroup>
          </div>

          <p className={styles.question}>
            <span>Which program(s) are you interested in?</span>
          </p>

          <div className={styles.box}>
            {program?.components?.sessionForm?.preferredCourseDates?.map(
              (option) => (
                <div className={styles.checkboxcontainer} key={option}>
                  <FormControlLabel
                    control={
                      <MentemCheckbox
                        checked={
                          submission?.dates?.includes(option) ? true : false
                        }
                        onChange={(e) => {
                          setSubmission({
                            ...submission!,
                            dates: e.target.checked
                              ? [...(submission?.dates || []), option]
                              : (submission?.dates || []).filter(
                                  (d) => d !== option,
                                ),
                          })
                        }}
                      />
                    }
                    label={option}
                  />
                </div>
              ),
            )}
          </div>

          <TextField
            value={submission?.otherInformation || ''}
            label="Other information"
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            onChange={(e) => {
              setSubmission({
                ...submission!,
                otherInformation: e.target.value,
              })
            }}
            multiline={true}
          />

          <div className={styles.checkboxcontainer}>
            <FormControlLabel
              control={
                <MentemCheckbox
                  checked={submission?.tncAgreeAt ? true : false}
                  onChange={(e) => {
                    setSubmission({
                      ...submission!,
                      tncAgreeAt: e.target.checked ? new Date() : undefined,
                    })
                  }}
                />
              }
              label={label}
            />
            <Grow in={getTncError()} style={{ transformOrigin: '0 50% 0' }}>
              <span className={styles.error}>Required</span>
            </Grow>
          </div>

          {submission?.program ? (
            <button
              onClick={handleClickSubmit}
              className={
                readyForSubmit()
                  ? styles.submitButtonPurple
                  : styles.submitButton
              }
            >
              Continue
            </button>
          ) : (
            <LoadingIndicator />
          )}
        </div>
      </div>
    </div>
  )
}
