import React from 'react'
import styles from './SessionHeroForRla.module.css'
import { useMediaQuery } from '@mui/material'

interface Props {
  title: string
  subtitle: string
  background: string
  titleColor: string
  subtitleColor: string
  titleSize: string
  subtitleSize: string
}

export const SessionHeroForRla: React.FC<Props> = ({
  title,
  subtitle,
  background,
  titleColor,
  subtitleColor,
  titleSize,
  subtitleSize,
}) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <div className={styles.container}>
      <div
        className={styles.heroImage}
        style={{
          backgroundImage: `url('${background}')`,
        }}
      >
        <div className={styles.heroLogo}>
          <div className={styles.heroText}>
            <h1
              className={styles.title}
              style={{
                color: titleColor,
                fontSize: titleSize,
              }}
            >
              {title.split('\n').map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </h1>
            <p className={styles.description}>
              <b>
                {!isMobile && (
                  <span
                    style={{ color: subtitleColor, fontSize: subtitleSize }}
                  >
                    {subtitle.split('\n').map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </span>
                )}
              </b>
            </p>
            <img
              src="/arrow-down-black.png"
              alt="arrow-down"
              className={styles.arrow}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
