import styles from './Edit.module.css'
import { useEffect, useState } from 'react'
import { Footer } from 'components/Footer'
import { LoadingIndicator } from 'components/LoadingIndicator'
import { Helmet } from 'react-helmet'
import EmailVerification from 'components/EmailVerification'
import { useDataContext } from 'context/DataProvider'
import { useParams } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

export default function Edit() {
  const { program, checkProgram } = useDataContext()
  const { clientCode, programCode } = useParams()
  const [title, setTitle] = useState('')
  const [subtitle, setSubtitle] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isMobile = useMediaQuery('(max-width: 768px)')

  useEffect(() => {
    checkProgram(clientCode, programCode)
  }, [checkProgram, clientCode, programCode])

  useEffect(() => {
    if (program) {
      setTitle(program.components.sessionForm.bannerTitle)
      setSubtitle(program.components.sessionForm.bannerSubtitle)
    }
  }, [program])

  return (
    <>
      <Helmet>
        <title>Email verification | Mentem by UNSW</title>
      </Helmet>
      <div className={styles.full}>
        <div className={styles.overlay} />
        <div className={styles.backgroundimage} />

        <div className={styles.full}>
          {isSubmitting && (
            <div
              style={{
                position: 'fixed',
                width: '100vw',
                height: '100vh',
                zIndex: '100000',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <LoadingIndicator />
            </div>
          )}
          <div
            style={{
              backgroundImage: `url(${program?.components.sessionForm.bannerBackgroundImageUrl})`,
              width: '100%',
              minHeight: '360px',
              backgroundPosition: '20% 50%',
              backgroundSize: 'cover',
            }}
            className={styles.banner}
          >
            <div className={styles.bannercontent}>
              <h1 style={{ fontSize: '42px' }}>
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </h1>
              {!isMobile && (
                <div style={{ fontSize: '27px', marginTop: '16px' }}>
                  <span dangerouslySetInnerHTML={{ __html: subtitle }} />
                </div>
              )}
              <div style={{ marginTop: '16px' }}>
                <img src="/arrow-down-white.png" alt="down" />
              </div>
            </div>
          </div>

          <div
            className={styles.container}
            style={{
              backgroundImage: `url(${program?.components.sessionForm.formBackgroundImageUrl})`,
            }}
          >
            <EmailVerification
              onLoading={(loading: boolean) => {
                setIsSubmitting(loading)
              }}
            />
          </div>
          <Footer
            backgroundColor={
              program?.components.footer.backgroundColor || '#000'
            }
          />
        </div>
      </div>
    </>
  )
}
