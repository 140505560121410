import React from 'react'
import styles from './GenericProgramTopic.module.css'
import { useMediaQuery } from '@mui/material'

interface Props {
  title: string
  items: any[]
}

export const GenericProgramTopic: React.FC<Props> = ({ title, items }) => {
  const isMobile = useMediaQuery('(max-width: 767px)')

  const handleClick = (link: string, url: string, title: string) => {
    localStorage.setItem(link, JSON.stringify({ url, title }))
    window.open(link, '_blank')
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.description}>{title}</p>
        <div className={styles.boxesstack}>
          {items.map((item, index) =>
            !isMobile ? (
              <div key={index} className={styles.block}>
                <img
                  className={styles.itemimage}
                  src={item.image}
                  alt={item.title}
                />
                <div className={styles.itemtitle}>{item.title}</div>

                <button
                  className={styles.itemcta}
                  onClick={() => handleClick(item.link, item.url, item.title)}
                >
                  {item.cta}
                </button>
              </div>
            ) : (
              <div key={index} className={styles.block2}>
                <div className={styles.blockcontainer}>
                  <div className={styles.frame}>
                    <div className={styles.itemtitle}>{item.title}</div>

                    <button
                      className={styles.itemcta}
                      onClick={() =>
                        handleClick(item.link, item.url, item.title)
                      }
                    >
                      {item.cta}
                    </button>
                  </div>
                </div>
                <img
                  className={styles.itemimage}
                  src={item.image}
                  alt={item.title}
                />
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  )
}
