import React from 'react'
import styles from './SessionBoxes.module.css'
import { useMediaQuery } from '@mui/material'

export const SessionBoxes: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  const background = ''
  const title = 'How to apply'

  const items = [
    {
      title: 'Step 1: Explore',
      description: 'Learn more through demos, testimonials and FAQs.',
      image: 'https://content.mentem.co/eoi/_share/explore.png',
      bgColor: 'rgba(225, 225, 236, 1)',
      color: 'rgba(0, 0, 0, 1)',
    },
    {
      title: 'Step 2: Share',
      description: 'Tell us about yourself via a short form.',
      image: 'https://content.mentem.co/eoi/_share/share.png',
      bgColor: 'rgba(186, 97, 255, 0.96)',
      color: 'rgba(255, 255, 255, 1)',
    },
    {
      title: 'Step 3: Test',
      description: 'Pre-course assessments to confirm shortlisted candidates.',
      image: 'https://content.mentem.co/eoi/_share/placement.png',
      bgColor: 'rgba(121, 76, 109, 1)',
      color: 'rgba(255, 255, 255, 1)',
    },
  ]

  return (
    <div
      className={styles.container}
      style={{ backgroundImage: `url('${background}')` }}
    >
      <div className={styles.content}>
        <h1 className={styles.title} style={{ marginTop: '30px' }}>
          {title}
        </h1>

        <div className={styles.boxesstack}>
          {items.map((item, index) => (
            <div
              key={index}
              className={styles.item}
              style={{ backgroundColor: item.bgColor, color: item.color }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: isMobile ? 'row' : 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '12px',
                }}
              >
                <img
                  style={{
                    width: isMobile ? '46px' : '60px',
                    height: isMobile ? '46px' : '60px',
                  }}
                  className={styles.boximage}
                  src={item.image}
                  alt={item.title}
                />
                <div className={styles.boxtitle}>
                  <b>{item.title}</b>
                </div>
              </div>
              <div className={styles.boxdescription}>
                <b>{item.description}</b>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
