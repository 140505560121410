import { useEffect, useRef, useState } from 'react'
import styles from './DynamicConversations.module.css'
import { useDataContext } from 'context/DataProvider'
import { Collapse, TextareaAutosize } from '@mui/material'
import { QuestionSpace } from 'apis/models/question.entity'
import { API } from 'apis/API'
import { jsonrepair } from 'jsonrepair'
import {
  GenerateQuestionSpaceQuestionDTO,
  GenerateQuestionSpaceQuestionResponseDTO,
  ProcessQuestionSpaceAnswerDTO,
  QuestionSpaceAnswerResponseDTO,
} from 'apis/models/API.dto'
import Flicking, { MoveEndEvent } from '@egjs/react-flicking'
import { LoadingIndicator } from './LoadingIndicator'
import { toastError, toastInfo } from 'utils/toast'
import { isProduction } from 'utils/EnvUtils'

type PageProps = {
  onContinue: () => void
  // questionSpaces: QuestionSpace[] | undefined
}

// const Question = ({ question, onChange }) => {
//   return (
//     <div key={question.id}>
//       <div className={styles.question}>{question.question}</div>
//       <TextareaAutosize
//         className={styles.textarea}
//         placeholder={question.placeholder}
//         minRows={3}
//         defaultValue={question.answer}
//         onChange={(e) => onChange(question.id, e.target.value)}
//       />
//     </div>
//   )
// }

const DynamicConversations = ({
  onContinue /*, questionSpaces*/,
}: PageProps) => {
  const { submission, setSubmission, program } = useDataContext()
  // const questionIndex = useRef(0)
  // const [questionAnswer, setQuestionAnswer] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isError, setIsError] = useState(false)
  // const [refreshCount, setRefreshCount] = useState(0)
  // const [questionSpace, setQuestionSpace] = useState<QuestionSpace | undefined>(
  //   questionSpaces?.length ? questionSpaces[0] : undefined,
  // )
  const [currentIndex, setCurrentIndex] = useState(0)
  const [questionSpaces, setQuestionSpaces] = useState<
    QuestionSpace[] | undefined
  >()
  const inputTextRef = useRef<HTMLTextAreaElement>(null)

  // const [questionSpace, setQuestionSpace] = useState<
  //   QuestionSpace | undefined
  // >()

  // console.log('dynamicQuestions:', submission)

  const [inputText, setInputText] = useState('')
  const flicking = useRef<Flicking>(null)

  useEffect(() => {
    // setQuestionSpace(program?.components?.sessionForm?.questionSpaces![0])
    setQuestionSpaces(program?.queryContext?.questionSpaces)
  }, [program])

  const checkAllSpaceSatistified = () => {
    return false
  }
  const handleClickContinue = async () => {
    // after dimension satisified
    // onContinue()

    await handleAnswer()

    if (checkAllSpaceSatistified()) {
      onContinue()
    } else {
    }
  }

  const processAnswerChunkCallback = async (
    message: string,
    accumulatedMessage: string,
    isFinished: boolean,
    params: any,
  ) => {
    //////// check response ////////
    let json: QuestionSpaceAnswerResponseDTO
    try {
      const repairedJsonText = jsonrepair(accumulatedMessage)
      // console.log('accumulatedMessage:', accumulatedMessage)
      // console.log('repaired JSON:', json)
      json = JSON.parse(repairedJsonText)
    } catch (e) {
      //There will be cases cannot be repaired throwing missing colon errors
      // console.error('error:', e)
      return
    }

    const newQuestion = json.followupQuestion
    const newQuestionIndex = newQuestion ? params.questionIndex + 1 : undefined

    //////// update data ////////
    //check questions array and init
    if (
      newQuestion &&
      (questionSpaces?.[currentIndex]?.questions?.length ?? 0) <=
        newQuestionIndex
    ) {
      console.log('init 1 more question')
      //init 1 more question
      questionSpaces?.[currentIndex]?.questions?.push({
        id: `${newQuestionIndex}`,
        question: '',
        answer: '',
      })
      // questionIndex.current++
    }

    //not finished flow, update question
    if (!isFinished && questionSpaces?.[currentIndex]) {
      //has new question
      if (
        (questionSpaces?.[currentIndex]?.questions?.length ?? 0) >
        newQuestionIndex
      ) {
        questionSpaces![currentIndex].questions![newQuestionIndex].question =
          newQuestion
        // console.log('new question:', newQuestion)
        // console.log('questions', questionSpaces[currentIndex].questions)
        setQuestionSpaces([...questionSpaces!])
        submission!.questionSpaces = [...questionSpaces!]
        setSubmission({ ...submission! }) //trigger form resize
      }

      // setQuestionSpace({ ...questionSpace })
      // setSubmission({
      //   ...submission!,
      // })
    }

    //finished flow, update dimensions
    if (isFinished && questionSpaces?.[currentIndex]) {
      console.log('final stream:', json)
      //has new question
      if (
        (questionSpaces?.[currentIndex].questions?.length ?? 0) >
        newQuestionIndex
      ) {
        questionSpaces[currentIndex].questions![newQuestionIndex].question =
          newQuestion
      }
      //update dimensions
      questionSpaces[currentIndex].dimensions = json.dimensions
      console.log(
        'questionSpace.dimensions:',
        questionSpaces?.[currentIndex].dimensions,
      )

      let displayText = json.dimensions
        .flatMap((d) =>
          d.dataPoints?.flatMap((dp) => `-${dp.label}: ${dp.metric}`),
        )
        .join('\n')
      if (json.comments) {
        displayText = displayText + '\n----------------\n' + json.comments
      }

      // const displayText = json.dimensions
      // .flatMap((d) =>
      //   d.dataPoints?.flatMap((dp) => `${dp.label}: ${dp.metric}`),
      // )
      // .join(', ')
      console.log('displayText:', displayText)

      if (!isProduction) {
        toastInfo(displayText, 10000)
      }

      // setQuestionSpace({ ...questionSpace })

      //no followup question
      if (!json.followupQuestion) {
        questionSpaces[currentIndex].isSufficient = true
        setTimeout(() => {
          nextSpace(currentIndex + 1)
        }, 300)
      }

      setQuestionSpaces([...questionSpaces!])
      submission!.questionSpaces = [...questionSpaces!]
      setSubmission({
        ...submission!,
      })

      if (isFinished) {
        console.log(`questionSpaces[${currentIndex}]:`, questionSpaces)
      }
    }
  }

  const processQuestionChunkCallback = async (
    message: string,
    accumulatedMessage: string,
    isFinished: boolean,
    params: any,
  ) => {
    //////// check response ////////
    let json: GenerateQuestionSpaceQuestionResponseDTO
    try {
      const repairedJsonText = jsonrepair(accumulatedMessage)
      // console.log('accumulatedMessage:', accumulatedMessage)
      // console.log('repaired JSON:', json)
      json = JSON.parse(repairedJsonText)
    } catch (e) {
      //There will be cases cannot be repaired throwing missing colon errors
      // console.error('error:', e)
      return
    }

    const newQuestion = json.question
    const spaceIndex = params.spaceIndex

    //////// update data ////////
    //check questions array and init
    if (
      newQuestion &&
      (questionSpaces?.[spaceIndex]?.questions?.length ?? 0) === 0
    ) {
      console.log('init 1 more question')
      //init 1 more question
      questionSpaces?.[spaceIndex]?.questions?.push({
        id: `${0}`,
        question: '',
        answer: '',
      })
      // questionIndex.current++
    }

    //not finished flow, update question
    if (!isFinished && questionSpaces?.[spaceIndex]) {
      questionSpaces![spaceIndex].questions![0].question = newQuestion
      // console.log('new question:', newQuestion)
      // console.log('questions', questionSpaces[currentIndex].questions)
      setQuestionSpaces([...questionSpaces!])
      submission!.questionSpaces = [...questionSpaces!]
      setSubmission({ ...submission! }) //trigger form resize

      // setQuestionSpace({ ...questionSpace })
      // setSubmission({
      //   ...submission!,
      // })
    }

    //finished flow, update dimensions
    if (isFinished && questionSpaces?.[currentIndex]) {
      console.log('final stream:', json)
      //has new question

      questionSpaces[spaceIndex].questions![0].question = newQuestion
      const shouldSkip = json.skip
      if (shouldSkip) {
        console.log('skip this question')
        questionSpaces[spaceIndex].isSufficient = true
        setTimeout(() => {
          nextSpace(spaceIndex + 1)
        }, 300)
      }
      setQuestionSpaces([...questionSpaces!])
      submission!.questionSpaces = [...questionSpaces!]
      setSubmission({ ...submission! }) //trigger form resize
    }
  }

  const handleAnswer = async () => {
    setIsError(false)
    console.log('handleAnswer')
    const questionAnswer = inputText.trim()
    if (!questionAnswer) {
      return
    }

    // console.log('questionIndex.current:', questionIndex.current)
    const questionIndex =
      (questionSpaces?.[currentIndex].questions?.length ?? 0) - 1

    // update answer
    if (
      //question exists
      questionSpaces?.[currentIndex] &&
      (questionSpaces?.[currentIndex].questions?.length ?? 0) > questionIndex
    ) {
      questionSpaces[currentIndex].questions![questionIndex].answer =
        questionAnswer
      console.log('questionAnswer:', questionAnswer)
    }

    setIsSubmitting(true)

    const pastQuestionSpaces = questionSpaces?.slice(0, currentIndex)
    const pastQuestionAnswers = pastQuestionSpaces
      ?.flatMap((qs) => qs.questions)
      .filter((q) => q?.answer)

    const dto: ProcessQuestionSpaceAnswerDTO = {
      email: submission!.email || '',
      programId: `${submission!.program}`,
      context: program?.queryContext!,
      questionSpace: questionSpaces![currentIndex],
      history: pastQuestionAnswers ?? [],
    }

    setQuestionSpaces([...questionSpaces!])
    submission!.questionSpaces = [...questionSpaces!]
    setSubmission({
      ...submission!,
    })

    const response = await API.processQuestionSpaceAnswerStream(
      dto,
      processAnswerChunkCallback,
      {
        questionIndex: questionIndex,
      },
    )
    if (response?.success === false) {
      toastError(`${response?.message}`)
      setIsError(true)
      setIsSubmitting(false)
      return
    }
    setInputText('')
    setIsSubmitting(false)
    //focus
    setTimeout(() => {
      inputTextRef.current?.focus()
      inputTextRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }, 600)
  }

  const fetchNewQuestion = async (spaceIndex: number) => {
    console.log('getQuestion')

    const pastQuestionSpace = questionSpaces?.slice(0, spaceIndex)
    const pastQuestionAnswers = pastQuestionSpace
      ?.flatMap((qs) => qs.questions)
      .filter((q) => q?.answer)
    const dto: GenerateQuestionSpaceQuestionDTO = {
      email: submission!.email || '',
      programId: `${submission!.program}`,
      questionSpace: questionSpaces![spaceIndex],
      history: pastQuestionAnswers ?? [],
    }

    setIsSubmitting(true)
    await API.generateQuestionSpaceQuestionStream(
      dto,
      processQuestionChunkCallback,
      {
        spaceIndex: spaceIndex,
      },
    )
    setIsSubmitting(false)
    setTimeout(() => {
      inputTextRef.current?.focus()
      inputTextRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }, 600)
  }

  // useEffect(() => {
  //   if (!submission?.questions || submission?.questions.length === 0) {
  //     setSubmission({ ...submission!, questions: questions })
  //   }
  // }, [questions, setSubmission, submission])

  const showResult = () => {
    console.log('showResult')
    const dimmensions = questionSpaces?.flatMap((qs) => qs.dimensions)
    console.log(dimmensions)
  }

  const handleTextChange = (value: string) => {
    //trim head
    value = value.trimStart()

    setInputText(value)
    setQuestionSpaces([...questionSpaces!])
    submission!.questionSpaces = [...questionSpaces!]
    setSubmission({ ...submission! }) //trigger form resize
    // setRefreshCount(refreshCount + 1)
    // setQuestionAnswer(inputText) //local state

    //if the user input enter 2 times at the end, submit
    if (value.endsWith('\n\n')) {
      handleClickContinue()
    }
  }
  const nextSpace = async (nextIndex: number) => {
    console.log('nextSpace:', nextIndex)
    if (nextIndex >= (questionSpaces?.length ?? 0)) {
      showResult()
      onContinue()
    } else {
      setCurrentIndex(nextIndex)
      setQuestionSpaces([...questionSpaces!])
      if (
        questionSpaces?.[nextIndex]?.questions?.length === 0 ||
        questionSpaces?.[nextIndex].questions?.[0].question === ''
      ) {
        console.log('nextIndex', nextIndex)
        console.log('fetch new question')
        await fetchNewQuestion(nextIndex)
      }
      submission!.questionSpaces = [...questionSpaces!]
      setSubmission({ ...submission! }) //trigger form resize
      flicking.current?.stopAnimation()
      flicking.current?.moveTo(nextIndex)
    }
  }

  const handleMoveEnd = (e: MoveEndEvent<Flicking>) => {
    // console.log('MoveEnd:', e)
    // setTimeout(() => {
    flicking.current?.resize()
    // setRefreshCount(refreshCount + 1)
    // setTimeout(() => {
    // console.log('refreshCount:', refreshCount + 1)
    // setRefreshCount(refreshCount + 1)
    setQuestionSpaces([...questionSpaces!])
    submission!.questionSpaces = [...questionSpaces!]
    setSubmission({ ...submission! }) //trigger form resize
    // }, 1000)
  }

  useEffect(() => {
    flicking.current?.resize()
  }, [questionSpaces])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 style={{ textAlign: 'center', marginBottom: '28px' }}>
          {program?.queryContext?.background?.name}
        </h1>
        {/* first load empty */}

        {/* {'(' +
                    program?.components?.sessionForm?.questionSpaces?.[
                      currentIndex
                    ]?.name +
                    ')'} */}
        {/* {questionSpaces?.[currentIndex]?.questions?.map(
              (q: any, qIndex) => (
                <div key={`${space.id}-${spaceIndex}-${q.id}-${qIndex}`}>
                  <div className={styles.question}>{q.question}</div>
                  <div className={styles.answer}>{q.answer}</div>
                </div>
              ),
            )} */}
        {questionSpaces?.map((questionSpace, spaceIndex) =>
          questionSpace?.questions?.map(
            (q: any, qIndex) =>
              spaceIndex <= currentIndex &&
              true && (
                <div
                  key={`${questionSpace.id}-${spaceIndex}-${q.id}-${qIndex}`}
                >
                  {q.question && (
                    <>
                      <div className={styles.question}>
                        {spaceIndex + 1}.{qIndex + 1} - {q.question}
                      </div>
                      <div className={styles.answer}>{q.answer}</div>
                    </>
                  )}
                </div>
              ),
          ),
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          key={'loading'}
        >
          <Collapse orientation="vertical" in={isSubmitting}>
            <div style={{ marginBottom: '20px' }}>
              <LoadingIndicator color="#dba9ff" />
            </div>
          </Collapse>
        </div>

        <Collapse
          orientation="vertical"
          in={
            !isSubmitting &&
            questionSpaces?.[currentIndex]?.isSufficient !== true &&
            (questionSpaces?.[currentIndex]?.questions?.[0]?.question ?? '') !==
              ''
          }
        >
          <TextareaAutosize
            ref={inputTextRef}
            disabled={isSubmitting}
            className={`${styles.textarea}`}
            placeholder={''}
            minRows={3}
            onChange={(e) => {
              handleTextChange(e.target.value)
            }}
            value={inputText}
            maxLength={4000}
          />
        </Collapse>

        <button
          style={{ marginTop: '20px', marginBottom: '20px' }}
          className={`${styles.submitbutton} ${
            isSubmitting ? styles.disabled : ''
          }`}
          onClick={handleClickContinue}
          disabled={isSubmitting}
        >
          Continue
        </button>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        ></div>
      </div>
    </div>
  )
}

export default DynamicConversations
