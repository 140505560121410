/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDataContext } from '../context/DataProvider'
import styles from './Welcome.module.css'
import { LoadingIndicator } from 'components/LoadingIndicator'
import {
  Checkbox,
  FormControlLabel,
  Grow,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import {
  dropdownItemStyles,
  dropdownOutlineLabelStyles,
  textFieldOutlineLabelStyles,
} from 'utils/MuiStyles'
import { useRef, useState } from 'react'
import { isEmail } from 'utils/stringUtils'

type PageProps = {
  onContinue: () => void
  businessUnits: string[]
  locations: string[]
  roleTypes: string[]
  acceptedEmailDomains: string[]
}

export default function Welcome({
  onContinue,
  businessUnits,
  locations,
  roleTypes,
  acceptedEmailDomains,
}: PageProps) {
  const { submission, setSubmission } = useDataContext()
  const [rerenderCount, setRerenderCount] = useState(0)
  const shouldValidate = useRef(false)

  //////////////////////////
  // data
  //////////////////////////
  const getFirstNameErrorMessage = () => {
    if (shouldValidate.current && submission?.firstName?.trim() === '') {
      return 'Please input your first name'
    } else return ''
  }

  const getEmailErrorMessage = () => {
    if (!shouldValidate.current) {
      return ''
    }
    const email = submission?.email || ''
    const emailDomain = email.substring(email.lastIndexOf('@')) || ''
    if (!isEmail(email)) {
      return 'Please input your work email address'
    }

    const env = process.env.REACT_APP_ENV
    if (env !== 'dev' && !acceptedEmailDomains.includes(emailDomain)) {
      return 'Please use your CBA-related work email address'
    }
    return ''
  }

  const getManagerFirstNameErrorMessage = () => {
    if (shouldValidate.current && !submission?.managerFirstName?.trim()) {
      return 'Please input the manager’s name'
    } else return ''
  }

  const getManagerEmailErrorMessage = () => {
    if (!shouldValidate.current) {
      return ''
    }
    const email = submission?.managerEmail || ''
    const emailDomain = email.substring(email.lastIndexOf('@')) || ''
    if (!isEmail(email)) {
      return `Please input the manager's work email`
    }
    if (!acceptedEmailDomains.includes(emailDomain)) {
      return `Please use the direct manager's CBA-related work email address`
    }
    // check if the email is the same as the user's email
    if (email === submission?.email) {
      return `Please input your manager’s email address`
    }
    return ''
  }

  const getTncError = () => {
    if (shouldValidate.current && !submission?.tncAgreeAt) {
      return true
    } else return false
  }

  const getBusinessUnitErrorMessage = () => {
    if (shouldValidate.current && !submission?.businessUnit?.trim()) {
      return 'Please select your business unit'
    } else return ''
  }

  const getLocationErrorMessage = () => {
    if (shouldValidate.current && !submission?.location?.trim()) {
      return 'Please select your location'
    } else return ''
  }
  const getRoleTypeErrorMessage = () => {
    if (
      shouldValidate.current &&
      (!submission?.roleType?.trim() ||
        (submission?.roleType === 'Other' && !submission?.role?.trim()))
    ) {
      return 'Please confirm your job family'
    } else return ''
  }

  const getRoleErrorMessage = () => {
    if (
      shouldValidate.current &&
      (!submission?.roleType?.trim() ||
        (submission?.roleType === 'Other' && !submission?.role?.trim()))
    ) {
      return 'Please specify'
    } else return ''
  }

  const tncLabel = (
    <span>
      I agree to the terms of Mentem's{' '}
      <span style={{ textDecoration: 'underline' }}>
        <a href="https://www.mentem.co/privacy/">Privacy Policy</a>
      </span>
    </span>
  )

  //////////////////////////
  // interactions
  //////////////////////////

  const handleClickContinue = () => {
    // console.log('submission:', submission)
    shouldValidate.current = true

    if (
      getFirstNameErrorMessage() ||
      getEmailErrorMessage() ||
      getBusinessUnitErrorMessage() ||
      getLocationErrorMessage() ||
      getManagerFirstNameErrorMessage() ||
      getManagerEmailErrorMessage() ||
      getRoleTypeErrorMessage() ||
      getRoleErrorMessage() ||
      getTncError()
    ) {
      console.log('Invalid profile')

      setRerenderCount(rerenderCount + 1)
    } else {
      shouldValidate.current = false
      onContinue()
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.description} style={{ fontSize: '26px' }}>
          Ready to apply? Let's get started!
        </div>
        <div className={styles.instructions}></div>
        <div className={styles.formcontainer}>
          <TextField
            value={submission?.firstName || ''}
            label={getFirstNameErrorMessage() || 'First Name'}
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            // InputProps={textFieldStyles} // Add borderRadius here
            onChange={(e) => {
              setSubmission({ ...submission!, firstName: e.target.value })
            }}
            error={getFirstNameErrorMessage() !== ''}
          />

          <TextField
            value={submission?.lastName || ''}
            label={'Last Name'}
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            onChange={(e) => {
              setSubmission({ ...submission!, lastName: e.target.value })
            }}
          />

          <TextField
            value={submission?.email || ''}
            label={getEmailErrorMessage() || 'Email'}
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            error={getEmailErrorMessage() !== ''}
            onChange={(e) => {
              setSubmission({ ...submission!, email: e.target.value })
            }}
          />

          <TextField
            value={submission?.managerFirstName || ''}
            label={getManagerFirstNameErrorMessage() || `Manager's First Name`}
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            error={getManagerFirstNameErrorMessage() !== ''}
            onChange={(e) => {
              setSubmission({
                ...submission!,
                managerFirstName: e.target.value,
              })
            }}
          />

          <TextField
            value={submission?.managerEmail || ''}
            label={getManagerEmailErrorMessage() || `Direct Manager's Email`}
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            error={getManagerEmailErrorMessage() !== ''}
            onChange={(e) => {
              setSubmission({ ...submission!, managerEmail: e.target.value })
            }}
          />

          <Select
            style={{
              width: '100%',
              border: '1px solid rgba(230, 230, 230, 1)',
              backgroundColor: 'rgba(255, 255, 255, 1)',
            }}
            variant="outlined"
            sx={dropdownOutlineLabelStyles}
            displayEmpty
            renderValue={(selected) => {
              // if (!selected) {
              const errorMessage = getBusinessUnitErrorMessage()
              return errorMessage ? (
                <div style={{ color: 'rgba(211,47,41,1)' }}>{errorMessage}</div>
              ) : submission?.businessUnit ? (
                <div style={{ color: 'rgba(0,0,0,1)' }}>
                  {submission?.businessUnit}
                </div>
              ) : (
                <div style={{ color: 'rgba(0,0,0,0.6)' }}>Business Unit</div>
              )
            }}
            value={submission?.businessUnit || ''}
            label="Business Unit"
            onChange={(e) => {
              setSubmission({ ...submission!, businessUnit: e.target.value })
            }}
          >
            <MenuItem disabled value="">
              <div style={{ color: 'rgba(0,0,0,0.9)' }}>Business Unit</div>
            </MenuItem>
            {businessUnits.map((unit) => (
              <MenuItem value={unit} sx={dropdownItemStyles} key={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>

          {/* role */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              height: '57px',
            }}
          >
            <Select
              style={{
                width: '100%',
                border: '1px solid rgba(230, 230, 230, 1)',
                backgroundColor: 'rgba(255, 255, 255, 1)',
              }}
              variant="outlined"
              sx={dropdownOutlineLabelStyles}
              displayEmpty
              renderValue={(selected) => {
                const errorMessage =
                  submission?.roleType !== 'Other' && getRoleTypeErrorMessage()
                return errorMessage ? (
                  <div style={{ color: 'rgba(211,47,41,1)' }}>
                    {errorMessage}
                  </div>
                ) : submission?.roleType ? (
                  <div style={{ color: 'rgba(0,0,0,1)' }}>
                    {submission?.roleType}
                  </div>
                ) : (
                  <div style={{ color: 'rgba(0,0,0,0.6)' }}>Job Family</div>
                )
              }}
              value={submission?.roleType || ''}
              label="Job Family"
              onChange={(e) => {
                setSubmission({ ...submission!, roleType: e.target.value })
              }}
            >
              <MenuItem disabled value="">
                <div style={{ color: 'rgba(0,0,0,0.9)' }}>Job Family</div>
              </MenuItem>
              {roleTypes.map((item) => (
                <MenuItem value={item} sx={dropdownItemStyles} key={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            {submission?.roleType === 'Other' && (
              <TextField
                value={submission?.role || ''}
                label={getRoleErrorMessage() || 'Role'}
                variant="outlined"
                sx={textFieldOutlineLabelStyles}
                error={getRoleErrorMessage() !== ''}
                onChange={(e) => {
                  setSubmission({ ...submission!, role: e.target.value })
                }}
              ></TextField>
            )}
          </div>

          <Select
            style={{
              width: '100%',
              border: '1px solid rgba(230, 230, 230, 1)',
              backgroundColor: 'rgba(255, 255, 255, 1)',
            }}
            variant="outlined"
            sx={dropdownOutlineLabelStyles}
            displayEmpty
            renderValue={(selected) => {
              // if (!selected) {
              const errorMessage = getLocationErrorMessage()
              return errorMessage ? (
                <div style={{ color: 'rgba(211,47,41,1)' }}>{errorMessage}</div>
              ) : submission?.location ? (
                <div style={{ color: 'rgba(0,0,0,1)' }}>
                  {submission?.location}
                </div>
              ) : (
                <div style={{ color: 'rgba(0,0,0,0.6)' }}>Location</div>
              )
            }}
            value={submission?.location || ''}
            label="Location"
            onChange={(e) => {
              setSubmission({ ...submission!, location: e.target.value })
            }}
          >
            <MenuItem disabled value="">
              <div style={{ color: 'rgba(0,0,0,0.9)' }}>Location</div>
            </MenuItem>
            {locations.map((unit) => (
              <MenuItem value={unit} sx={dropdownItemStyles} key={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>
          <TextField
            value={submission?.linkedIn || ''}
            label="LinkedIn Profile URL (Optional)"
            variant="outlined"
            sx={textFieldOutlineLabelStyles}
            onChange={(e) => {
              setSubmission({ ...submission!, linkedIn: e.target.value })
            }}
          ></TextField>

          <div className={styles.checkboxcontainer}>
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={submission?.tncAgreeAt ? true : false}
                  sx={{
                    '&.Mui-checked': {
                      color: 'rgba(186, 97, 255, 1)',
                    },
                  }}
                  onChange={(e) => {
                    setSubmission({
                      ...submission!,
                      tncAgreeAt: e.target.checked ? new Date() : undefined,
                    })
                  }}
                />
              }
              label={tncLabel}
              sx={{
                '& .MuiSvgIcon-root': { fontSize: 28 },
              }}
            />
            <Grow in={getTncError()} style={{ transformOrigin: '0 50% 0' }}>
              <div className={styles.error}>Required</div>
            </Grow>
          </div>

          {submission?.program ? (
            <button
              onClick={handleClickContinue}
              className={styles.submitbutton}
            >
              Continue
            </button>
          ) : (
            <LoadingIndicator />
          )}
        </div>
      </div>
    </div>
  )
}
