import { useDataContext } from 'context/DataProvider'
import styles from './GenericFormThankYouV2.module.css'
import { useNavigate } from 'react-router-dom'
import { isCustomDomain, isDemoDomain } from 'utils/EnvUtils'

export default function GenericFormThankYouV2() {
  const navigate = useNavigate()
  const { program } = useDataContext()

  const handleClickContinue = () => {
    if (isDemoDomain) {
      // demo domain go back to root
      navigate('/')
      return
    }

    if (isCustomDomain()) {
      // custom domain go back to root/{programCode}
      const programCode = window.location.pathname.split('/')[1]
      navigate(`/${programCode}`)
      return
    }

    // back to home page by program slug
    if (program) {
      navigate(`/${program.slug}`)
    }
  }

  const email = program?.components?.sessionForm?.thankYouEmail

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.main}>
          <img
            src="/images/charm-circle-tick.png"
            alt="tick"
            className={styles.img}
          />
          <div className={styles.title}>
            {program?.components?.sessionForm?.thankYouTitle}
          </div>
          <div className={styles.item}>
            {program?.components?.sessionForm?.thankYouDescription1}
          </div>
          <div className={styles.item}>
            <span>
              {program?.components?.sessionForm?.thankYouDescription2}{' '}
              <span style={{ color: 'rgba(186, 97, 255, 1)' }}>
                <a href={`mailto:${email}`}>{email}</a>
              </span>
            </span>
          </div>
        </div>
        <button onClick={handleClickContinue} className={styles.submitbutton}>
          {program?.components?.sessionForm?.thankYouButton}
        </button>
      </div>
    </div>
  )
}
