import {
  CreateActivityDto,
  CreateSubmissionDto,
  GenerateQuestionSpaceQuestionDTO,
  GetProgramDto,
  PopulateQuestionSpacesDTO,
  ProcessQueryContextDTO,
  ProcessQuestionSpaceAnswerDTO,
  SummariseQueryContextDTO,
  UpdateProgramQueryContextDTO,
} from './models/API.dto'
import { Activity } from './models/activity.entity'
import { Program } from './models/program.entity'
import { Submission } from './models/submission.entity'
import apiClient from './utils/AxiosClient'
import { fetchStream, ProcessMessageCallback } from './utils/StreamProcessor'

const streamApi = process.env.REACT_APP_EOI_API_URL

export interface ConfirmEmailResponse {
  isManager: boolean
  learnerConfirmation: boolean
  learnerConfirmationAt: string
  managerConfirmation: boolean
  managerConfirmationAt: string
  managerConsent: boolean
  managerConsentAt: string
}

export const API = {
  processQuestionSpaceAnswerStream: async (
    dto: ProcessQuestionSpaceAnswerDTO,
    processMessageCallback: ProcessMessageCallback,
    params: any,
  ): Promise<any> => {
    const url = `${streamApi}/question`
    console.log('processQuestionSpaceAnswerStream started:')
    const response = await fetchStream(url, dto, processMessageCallback, params)
    console.log('processQuestionSpaceAnswerStream await finished:', response)
    return response
  },

  generateQuestionSpaceQuestionStream: async (
    dto: GenerateQuestionSpaceQuestionDTO,
    processMessageCallback: ProcessMessageCallback,
    params: any,
  ): Promise<any> => {
    const url = `${streamApi}/question/question`
    await fetchStream(url, dto, processMessageCallback, params)
  },

  processQueryContextStream: async (
    dto: ProcessQueryContextDTO,
    processMessageCallback: ProcessMessageCallback,
    params: any,
  ): Promise<any> => {
    const url = `${streamApi}/question/context`
    await fetchStream(url, dto, processMessageCallback, params)
  },

  populateQuestionSpacesStream: async (
    dto: PopulateQuestionSpacesDTO,
    processMessageCallback: ProcessMessageCallback,
    params: any,
  ): Promise<any> => {
    const url = `${streamApi}/question/populate`
    await fetchStream(url, dto, processMessageCallback, params)
  },

  summariseQueryContextStream: async (
    dto: SummariseQueryContextDTO,
    processMessageCallback: ProcessMessageCallback,
    params: any,
  ): Promise<any> => {
    const url = `${streamApi}/question/summary`
    await fetchStream(url, dto, processMessageCallback, params)
  },

  updateProgramQueryContext: async (
    dto: UpdateProgramQueryContextDTO,
  ): Promise<any> => {
    let url = `/programs/${dto.clientCode}/${dto.programCode}/context`
    console.log('url:', url)
    const response: any = await apiClient.patch(url, dto.queryContext)
    return response.data
  },

  getProgram: async (dto: GetProgramDto): Promise<Program> => {
    let url = `/programs/${dto.clientCode}/${dto.programCode}`
    const response: any = await apiClient.get(url)
    return response.data
  },

  getTopicUrl: async (topicCode: string): Promise<any> => {
    let url = `/programs/topics/${topicCode}`
    const response: any = await apiClient.get(url)
    return response.data
  },

  createSubmission: async (
    dto: CreateSubmissionDto,
  ): Promise<Submission | undefined> => {
    let url = `/submissions`

    // lowercase email & managerEmail
    if (dto.email) {
      dto.email = dto.email.toLowerCase()
    }
    if (dto.managerEmail) {
      dto.managerEmail = dto.managerEmail.toLowerCase()
    }

    const response: any = await apiClient.post(url, dto)
    return response.data
  },

  updateSubmission: async (
    id: string,
    dto: CreateSubmissionDto,
  ): Promise<Submission | undefined> => {
    let url = `/submissions/${id}`

    // lowercase email & managerEmail
    if (dto.email) {
      dto.email = dto.email.toLowerCase()
    }
    if (dto.managerEmail) {
      dto.managerEmail = dto.managerEmail.toLowerCase()
    }

    const response: any = await apiClient.patch(url, dto)
    return response.data
  },

  createOrUpdateSubmission: async (
    id: string | undefined,
    dto: CreateSubmissionDto,
  ): Promise<Submission | undefined> => {
    if (dto.email) {
      dto.email = dto.email.toLowerCase()
    }
    if (dto.managerEmail) {
      dto.managerEmail = dto.managerEmail.toLowerCase()
    }

    if (id) {
      return API.updateSubmission(id, dto)
    } else {
      return API.createSubmission(dto)
    }
  },

  createActivity: async (
    dto: CreateActivityDto,
  ): Promise<Activity | undefined> => {
    let url = `/activities`

    // lowercase email & managerEmail
    if (dto.email) {
      dto.email = dto.email.toLowerCase()
    }
    if (dto.managerEmail) {
      dto.managerEmail = dto.managerEmail.toLowerCase()
    }

    const response: any = await apiClient.post(url, dto)
    return response.data
  },

  validateToken: async (
    token: string,
  ): Promise<{ success: boolean; status: number }> => {
    let url = `/submissions/tokens/${token}`
    const response: any = await apiClient.get(url)
    // if response is 200, token is valid
    return { success: response.status === 200, status: response.status }
  },

  getSubmissionByToken: async (
    token: string,
    email: string,
  ): Promise<Submission | undefined> => {
    // post email to /submissions/tokens/:token
    let url = `/submissions/tokens/${token}`
    const response: any = await apiClient.post(url, { email })
    return response.data
  },

  confirmManagerConsent: async (
    token: string,
    value: boolean,
  ): Promise<{ success: boolean } | undefined> => {
    let url = `/submissions/manager-consent`
    const response: any = await apiClient.post(url, { token, value })
    return response.data
  },

  confirmEmail: async (
    token: string,
    value: boolean,
  ): Promise<{ success: boolean }> => {
    let url = `/submissions/email-confirmation`
    const response: any = await apiClient.post(url, { token, value })
    return response.data
  },

  getConfirmEmailStatus: async (
    token: string,
  ): Promise<ConfirmEmailResponse> => {
    let url = `/submissions/email-confirmation/${token}`
    const response: any = await apiClient.get(url)
    return response.data
  },

  dummyCall: async (delay: number) =>
    new Promise((resolve) => setTimeout(() => resolve('ok'), delay)),
  // const result1 = await APIQueue.getInstance().enqueue(() => API.dummyCall(2000))
}
